import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import CustomLabel from '../../../common/CustomLabel';
import CustomInput from '../../../common/CustomInput';
import Style from './style.module.scss';
import CategoryCardSection from './CategoryCardSection';
import { useDispatch, useSelector } from 'react-redux';
import {
    EmptyCustomerInformationPoliciesAction,
    GstApiIntegrationAction,
    SaveAboutBusinessAction,
    SaveBusinessTypeAction,
    SaveSelectedProductsAction,
} from '../../../Redux/Actions/CustomerInformationAction';
import { IReduxState } from '../../../utils/types';
import { annualTurnoverOptions } from '../../../utils/StaticDatas';
import { RecommendationApiIntegrationAction } from '../../../Redux/Actions/RecommendationAction';
import FullPageSpinner from '../../../common/FullPageSpinner';
import { UpdateUserProfileAction } from '../../../Redux/Actions/UserProfileAction';
import { businessCategories } from '../../../utils/QuoteData/BusineesCategories';
import ArrowBack from '../../../assets/images/arrow-left.svg';
import MobileArrowBack from '../../../assets/images/mobile-left.svg';
import CustomButton from '../../../common/Buttons/CustomButton';
import { UpdateUserSessionAction } from '../../../Redux/Actions/SessionAction';
import { Col, Row } from 'react-bootstrap';
import styles from './style.module.scss';
import { DecryptData } from '../../../common/CryptoJSToken';
import { TOAST_SHOW } from '../../../Redux/Actions/ToastAction';

interface AboutBusinessProps {
    buttonClicks: boolean;
    setSubmitButtonClick: any;
    formFilled: any;
    setFormFilled: any;
    buttonDisable: boolean;
    setButtonDisable: any;
    setIndex: any;
    setButtonClick: any;
    index: number;
    businessData: any;
}

// add validation for proceed ,  dispatch({
//   type: TOAST_SHOW,
//   payload: {
//     message: 'Please Click Proceed ',
//     severity: 'danger',
//     show: true,
//   },
// });

function AboutBusiness(props: AboutBusinessProps) {
    const dispatch = useDispatch();
    const [knowsGST, setKnowsGST] = useState(true);
    const [headerBox, setHeaderBox] = useState(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [gstin, setGstin] = useState('');
    const [disableGstInfo, setDisableGstInfo] = useState(false);
    const [category, setCategory] = useState('');
    const [legalName, setLegalName] = useState('');
    const [annualTurnover, setAnnualTurnover] = useState('');
    const [isFunded, setIsFunded] = useState(false);
    const [employeesCount, setEmployeesCount] = useState<number | any>('');
    const [fundingAmount, setFundingAmount] = useState('');
    const [address, setAddress] = useState<any>('');
    const [selectedCategory, setSelectedCategory] = useState<any>(null);
    const [formErrors, setFormErrors] = useState<any>({
        legalName: '',
        annualTurnover: '',
        fundingType: '',
        fundingAmount: '',
        noOfEmployee: '',
        businessCategory: '',
        address: '',
        businessType: '',
        gstin: '',
        email: '',
        firstName: '',
        lastName: '',
    });
    const CustomerInformation = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );

    const { aboutUser } = CustomerInformation;
    const userDetails = useSelector((state: IReduxState) => state.Auth);
    const userData = DecryptData(userDetails.user);

    const navigate = useNavigate();

    const customInfo = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );
    const { gstData, policies, businessCategory, aboutBusiness } = customInfo;

    const { loading, navigator } = useSelector(
        (state: IReduxState) => state.Recommendations,
    );
    const userSession = useSelector((state: IReduxState) => state.UserSession);

    useEffect(() => {
        if (props.businessData) {
            setAddress(props.businessData.address);
            setAnnualTurnover(props.businessData.annualTurnover);
            categoryHandleClick(props.businessData.businessCategory);
            setFundingAmount(props.businessData.fundingAmount);
            setIsFunded(props.businessData.fundingType);
            gstHandleChange(props.businessData.gstin);
            setLegalName(props.businessData.legalName);
            setEmployeesCount(props.businessData.noOfEmployee);
        }
    }, []);

    useEffect(() => {
        if (isGstEmpty()) {
            setHeaderBox(false);
            setDisableGstInfo(false);
        } else {
            setHeaderBox(true);
            setGstin(gstData.gstin);
            setLegalName(gstData.legalName);
            setAnnualTurnover(gstData.annualTurnover);
            setAddress(gstData.address);
            setDisableGstInfo(true);
            setSelectedCategory(businessCategory)
            setCategory(aboutBusiness.businessCategory);

            setEmployeesCount(aboutBusiness.noOfEmployee);
            dispatch(
                UpdateUserSessionAction({
                    id: userSession.id,
                    userSessionData: {
                        gstData: gstData,
                    },
                }),
            );
        }
    }, [gstData, businessCategory, aboutBusiness]);


    useEffect(() => {
        if (!knowsGST) {
            if (policies && policies.length === 0) {
                if (
                    firstName &&
                    lastName &&
                    category &&
                    legalName &&
                    annualTurnover &&
                    address &&
                    employeesCount
                ) {
                    props.setButtonDisable(false);
                } else {
                    props.setButtonDisable(true);
                }
            } else {
                if (address) {
                    props.setButtonDisable(false);
                } else {
                    props.setButtonDisable(true);
                }
            }
        } else {
            if (policies && policies.length === 0) {
                if (
                    gstin &&
                    category &&
                    legalName &&
                    annualTurnover &&
                    address &&
                    employeesCount
                ) {
                    props.setButtonDisable(false);
                } else {
                    props.setButtonDisable(true);
                }
            } else {
                if (address) {
                    props.setButtonDisable(false);
                } else {
                    props.setButtonDisable(true);
                }
            }
        }
    }, [
        firstName,
        lastName,
        category,
        gstin,
        category,
        legalName,
        annualTurnover,
        address,
        employeesCount,
        fundingAmount,
    ]);

    useEffect(() => {
        setFirstName(userData.first_name);
        setLastName(userData.last_name);
        setEmail(userData.email);
    }, []);

    useEffect(() => {
        if (Object.keys(aboutUser).length !== 0) {
            setFirstName(aboutUser.firstName);
            setLastName(aboutUser.lastName);
            setEmail(aboutUser.email);
        }
    }, [aboutUser]);

    const categoryHandleClick = (name: string) => {
        setCategory(name);
        // setSelectedCategory(name);
        const selectedCategoryObject = businessCategories.find(
            (category) => category.name === name,
        );
        if (selectedCategoryObject) {
            const selectedCategoryType = selectedCategoryObject.businessType;
            setSelectedCategory(selectedCategoryType);
        }
    };

    // useEffect(() => {
    //     if (navigator === true) {
    //         props.setIndex(2);
    //     }
    // }, [navigator]);

    const handleKnowsGSTChange = (e: any) => {
        setKnowsGST(e.target.value === 'yes');
    };

    const gstHandleChange = (e: string) => {
        const gst: string = e;
        const regexPattern =
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
        const comparedGst = regexPattern.test(gst);
        setGstin(gst);

        if (comparedGst) {
            setFormErrors((prev: any) => {
                return {
                    ...prev,
                    gstin: '',
                };
            });
            dispatch(
                GstApiIntegrationAction({
                    gstin: gst,
                }),
            );
        } else {
            setFormErrors((prev: any) => {
                return {
                    ...prev,
                    gstin: 'Invalid GST number.',
                };
            });
        }
    };

    const addressHandleChange = (e: any) => {
        const updatedAddress = e.target.value;
        setAddress(updatedAddress);
    };

    const isGstEmpty = () => {
        return Object.keys(gstData).length === 0;
    };

    const proceedHandleClick = () => {
        if (gstin && legalName && address && annualTurnover) {
            setDisableGstInfo(true);
        }
    };

    const handleFirstNameChange = (e: any) => {
        setFirstName(e.target.value);

        if (!e.target.value || e.target.value === '') {
            setFormErrors((prev: any) => {
                return {
                    ...prev,
                    firstName: 'Please enter an valid first name.',
                };
            });
        } else {
            setFormErrors((prev: any) => {
                return {
                    ...prev,
                    firstName: '',
                };
            });
        }
    };

    const handleLastNameChange = (e: any) => {
        setLastName(e.target.value);

        if (!e.target.value || e.target.value === '') {
            setFormErrors((prev: any) => {
                return {
                    ...prev,
                    lastName: 'Please enter an valid last name.',
                };
            });
        } else {
            setFormErrors((prev: any) => {
                return {
                    ...prev,
                    lastName: '',
                };
            });
        }
    };

    const emailErrorHandleCheck = (email: string) => {
        return email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    };

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
        if (!emailErrorHandleCheck(e.target.value)) {
            setFormErrors((prev: any) => {
                return {
                    ...prev,
                    email: 'Invalid email address.',
                };
            });
        } else {
            setFormErrors((prev: any) => {
                return {
                    ...prev,
                    email: '',
                };
            });
        }
    };

    const handleLegalNameChange = (e: any) => {
        setLegalName(e.target.value);
    };

    const handleAnnualTurnoverChange = (e: any) => {
        setAnnualTurnover(e.target.value);
    };

    const handleEmployeesCountChange = (e: any) => {
        setEmployeesCount(e.target.value);
    };

    const showCategorySection = address && address.length > 0;

    const handleNextButtonClick = () => {
        // validation check.
        let validSubmission = true;

        //validate first name , last name and email

        if (!firstName || firstName === '') {
            validSubmission = false;
            setFormErrors((prev: any) => {
                return {
                    ...prev,
                    firstName: 'Please enter an valid first name.',
                };
            });
        }

        if (!lastName || lastName === '') {
            validSubmission = false;
            setFormErrors((prev: any) => {
                return {
                    ...prev,
                    lastName: 'Please enter an valid last name.',
                };
            });
        }

        if (!email || email === '' || !emailErrorHandleCheck(email)) {
            validSubmission = false;
            setFormErrors((prev: any) => {
                return {
                    ...prev,
                    email: 'Please enter an valid email address.',
                };
            });
        }
        // validate gst no

        if (knowsGST) {
            // check if not empty
            if (gstin && !isGstEmpty()) {
                //check if valid
                const regexPattern =
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
                const validGst = regexPattern.test(gstin);
                if (!validGst) {
                    validSubmission = false;
                    setFormErrors((prev: any) => {
                        return {
                            ...prev,
                            gstin: 'Invalid GST number.',
                        };
                    });
                } else {
                    setFormErrors((prev: any) => {
                        return {
                            ...prev,
                            gstin: '',
                        };
                    });
                }
            }
        }

        // validate legal name
        if (!legalName || legalName === '') {
            validSubmission = false;
            setFormErrors((prev: any) => {
                return {
                    ...prev,
                    legalName: 'Please enter an valid legal name.',
                };
            });
        }

        // validate address
        if (!address || address === '') {
            validSubmission = false;
            setFormErrors((prev: any) => {
                return {
                    ...prev,
                    address: 'Please enter an valid address.',
                };
            });
        }

        // validate business category
        if (!category || category === '') {
            validSubmission = false;
            setFormErrors((prev: any) => {
                return {
                    ...prev,
                    businessCategory: 'Please select an business category.',
                };
            });
        }

        // if validated then run bw
        if (validSubmission) {

            const data = {
                legalName: legalName,
                annualTurnover: annualTurnover,
                fundingType: isFunded,
                fundingAmount: fundingAmount,
                noOfEmployee: employeesCount,
                businessCategory: category || businessCategory,
                address: address,
                businessType: selectedCategory,
                gstin: gstin,
            };
            const formData = new FormData();
            formData.append('address', address);

            if (businessCategory && businessCategory !== "") {
                if (data.businessType !== businessCategory) {
                    dispatch(EmptyCustomerInformationPoliciesAction())
                }
            }
            // condition here to empty policie on type change
            dispatch(SaveAboutBusinessAction(data));
            dispatch(SaveBusinessTypeAction(selectedCategory));
            dispatch(RecommendationApiIntegrationAction(data));
            dispatch(UpdateUserProfileAction(formData));
            dispatch(
                UpdateUserSessionAction({
                    id: userSession.id,
                    userSessionData: {
                        businessData: data,
                        gstData: data,
                    },
                }),
            );

            props.setIndex(2);
            props.setSubmitButtonClick(false);
            props.setButtonClick(false);

            dispatch(
                SaveSelectedProductsAction({
                    businessCategory: selectedCategory,
                    policies,
                }),
            );


            props.setButtonClick(true);
            if (props.index === 0 || props.index === 1) {
                props.setSubmitButtonClick(true);
            }
        } else {
            dispatch({
                type: TOAST_SHOW,
                payload: {
                    message:
                        'There may be invalid fields. Please review the form and try again.',
                    severity: 'danger',
                    show: true,
                },
            });
        }
    };

    return (
        <>
            <section className={styles.quote_login_auth_section}>
                <div className="quote-login-auth-box">
                    <h6>Let's start with you</h6>
                </div>
            </section>
            <div className={Style.container}>
                <Row className={Style.main}>
                    <h6 className={Style.heading}>About you</h6>
                    <Col lg={6}>
                        <Form.Group className="form-group">
                            <CustomLabel
                                label="First Name"
                                required
                                className={styles.customFieldLabel}
                            />
                            <CustomInput
                                required={true}
                                placeholder="Enter your First Name"
                                name="firstName"
                                value={firstName}
                                onChange={handleFirstNameChange}
                                isInvalid={
                                    formErrors &&
                                    formErrors.firstName &&
                                    formErrors?.firstName !== ''
                                }
                            />
                            {formErrors &&
                                formErrors.firstName &&
                                formErrors?.firstName !== '' && (
                                    <div className="text-error">
                                        {formErrors.firstName}
                                    </div>
                                )}
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="form-group">
                            <CustomLabel
                                className={styles.customFieldLabel}
                                label="Last Name *"
                            />
                            <CustomInput
                                required={true}
                                placeholder="Enter your Last Name"
                                value={lastName}
                                name="lastName"
                                onChange={handleLastNameChange}
                                isInvalid={
                                    formErrors &&
                                    formErrors.lastName &&
                                    formErrors?.lastName !== ''
                                }
                            />
                            {formErrors &&
                                formErrors.lastName &&
                                formErrors?.lastName !== '' && (
                                    <div className="text-error">
                                        {formErrors.lastName}
                                    </div>
                                )}
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="form-group">
                            <CustomLabel
                                className={styles.customFieldLabel}
                                label="Email* "
                            />
                            <CustomInput
                                placeholder="Enter your Email "
                                name="email"
                                value={email}
                                onChange={handleEmailChange}
                                isInvalid={
                                    formErrors &&
                                    formErrors.email &&
                                    formErrors?.email !== ''
                                }
                            />
                            {formErrors &&
                                formErrors.email &&
                                formErrors?.email !== '' && (
                                    <div className="text-error">
                                        {formErrors.email}
                                    </div>
                                )}
                        </Form.Group>
                    </Col>
                </Row>
            </div>
            <div className={Style.container}>
                <Row className={Style.main}>
                    <h6 className={Style.heading}>GST Information</h6>
                    {!headerBox && (
                        <Form.Group className={`form-group ${Style.container2}`}>
                            <p>
                                Do you know your GST No.? If you don't know it
                                or prefer not to provide it now, you can select
                                "I don't know" from the options.
                            </p>
                            <div className={Style.radioButtons}>
                                <label>
                                    <input
                                        type="radio"
                                        name="knowsGST"
                                        value="yes"
                                        checked={knowsGST}
                                        onChange={handleKnowsGSTChange}
                                    />
                                    Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="knowsGST"
                                        value="no"
                                        checked={!knowsGST}
                                        onChange={handleKnowsGSTChange}
                                    />
                                    I don’t know
                                </label>
                            </div>
                        </Form.Group>
                    )}

                    <div className={Style.knowGst}>
                        {knowsGST ? (
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        {isGstEmpty() ? (
                                            <CustomLabel
                                                className={
                                                    styles.customFieldLabel
                                                }
                                                label="Enter the GST No. *"
                                            />
                                        ) : (
                                            <CustomLabel
                                                className={
                                                    styles.customFieldLabel
                                                }
                                                label="Your GST No. *"
                                            />
                                        )}
                                        <CustomInput
                                            placeholder="Enter your GST number"
                                            disabled={disableGstInfo}
                                            name="gstin"
                                            value={gstin}
                                            onChange={(e: any) => {
                                                gstHandleChange(e.target.value);
                                            }}
                                            isInvalid={
                                                formErrors &&
                                                formErrors.gstin &&
                                                formErrors?.gstin !== ''
                                            }
                                        />
                                        {formErrors &&
                                            formErrors.gstin &&
                                            formErrors?.gstin !== '' && (
                                                <div className="text-error">
                                                    {formErrors.gstin}
                                                </div>
                                            )}
                                    </Form.Group>
                                </Col>
                                {/* {!isGstEmpty() && (
                                    <>
                                        <p>
                                            Here's the information we've
                                            retrieved from your GST No.
                                        </p>
                                        <Form.Group className="form-group">
                                            <CustomLabel className={styles.customFieldLabel} label="Legal Name of Your Business *" />
                                            <CustomInput
                                                name="legalName"
                                                disabled={disableGstInfo}
                                                value={legalName}
                                                placeholder="Enter your business name"
                                                onChange={(e: any) =>
                                                    setLegalName(e.target.value)
                                                }
                                            />
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <CustomLabel className={styles.customFieldLabel} label="Your Approximate Annual Revenue *" />
                                            <Form.Select
                                                name="annualTurnover"
                                                disabled={disableGstInfo}
                                                value={annualTurnover}
                                                onChange={(e: any) => {
                                                    setAnnualTurnover(
                                                        e.target.value,
                                                    );
                                                }}
                                            >
                                                {annualTurnover && (
                                                    <option
                                                        value={annualTurnover}
                                                    >
                                                        {annualTurnover}
                                                    </option>
                                                )}
                                                {annualTurnoverOptions.map(
                                                    (option) => (
                                                        <option
                                                            key={option}
                                                            value={option}
                                                        >
                                                            {option}
                                                        </option>
                                                    ),
                                                )}
                                            </Form.Select>
                                        </Form.Group>
                                        {annualTurnover && (
                                            <>
                                                <CustomLabel className={styles.customFieldLabel} label="Address *" />
                                                <Form.Group className="form-group">
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={2}
                                                        name="address"
                                                        value={address}
                                                        disabled={
                                                            disableGstInfo
                                                        }
                                                        onChange={
                                                            addressHandleChange
                                                        }
                                                    />
                                                </Form.Group>
                                            </>
                                        )}
                                        {!businessCategory && (
                                            <p className={Style.textItems}>
                                                If any of the retrieved
                                                information is incorrect or
                                                needs editing, please make the
                                                necessary changes and click
                                                <span
                                                    onClick={proceedHandleClick}
                                                >
                                                    {' '}
                                                    Proceed{' '}
                                                </span>
                                            </p>
                                        )}
                                    </>
                                )} */}
                                {!isGstEmpty() && (
                                    <>
                                        <Col lg={6}>
                                            <Form.Group className="form-group">
                                                <CustomLabel
                                                    className={
                                                        styles.customFieldLabel
                                                    }
                                                    label="Legal Name for your Business"
                                                    required
                                                />
                                                <CustomInput
                                                    required={true}
                                                    type="text"
                                                    disabled={disableGstInfo}
                                                    placeholder="Eg: Covrzy"
                                                    name="Legal Name for your Business"
                                                    value={legalName}
                                                    onChange={
                                                        handleLegalNameChange
                                                    }
                                                    isInvalid={
                                                        formErrors &&
                                                        formErrors.legalName &&
                                                        formErrors?.legalName !==
                                                        ''
                                                    }
                                                />
                                                {formErrors &&
                                                    formErrors.legalName &&
                                                    formErrors?.legalName !==
                                                    '' && (
                                                        <div className="text-error">
                                                            {
                                                                formErrors.legalName
                                                            }
                                                        </div>
                                                    )}
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="form-group">
                                                <CustomLabel
                                                    className={
                                                        styles.customFieldLabel
                                                    }
                                                    label="Address of your Business"
                                                    required
                                                />
                                                <CustomInput
                                                    required={true}
                                                    type="text"
                                                    disabled={disableGstInfo}
                                                    placeholder="Eg:  Unit A506, Carlton Towers, 560008"
                                                    name="Address of your Business"
                                                    value={address}
                                                    onChange={
                                                        addressHandleChange
                                                    }
                                                    isInvalid={
                                                        formErrors &&
                                                        formErrors.address &&
                                                        formErrors?.address !==
                                                        ''
                                                    }
                                                />
                                                {formErrors &&
                                                    formErrors.address &&
                                                    formErrors?.address !==
                                                    '' && (
                                                        <div className="text-error">
                                                            {formErrors.address}
                                                        </div>
                                                    )}
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="form-group">
                                                <CustomLabel
                                                    className={
                                                        styles.customFieldLabel
                                                    }
                                                    label="Company Turnover"
                                                    required
                                                />
                                                <Form.Select
                                                    name="annualTurnover"
                                                    disabled={disableGstInfo}
                                                    value={annualTurnover}
                                                    onChange={(e: any) => {
                                                        setAnnualTurnover(
                                                            e.target.value,
                                                        );
                                                    }}
                                                >
                                                    {annualTurnover && (
                                                        <option
                                                            value={
                                                                annualTurnover
                                                            }
                                                        >
                                                            {annualTurnover}
                                                        </option>
                                                    )}
                                                    {annualTurnoverOptions.map(
                                                        (option) => (
                                                            <option
                                                                key={option}
                                                                value={option}
                                                            >
                                                                {option}
                                                            </option>
                                                        ),
                                                    )}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="form-group">
                                                <CustomLabel
                                                    label="Your Business Industry"
                                                    required
                                                />
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    onChange={(e) =>
                                                        categoryHandleClick(
                                                            e.target.value,
                                                        )
                                                    }
                                                    isInvalid={
                                                        formErrors &&
                                                        formErrors.businessCategory &&
                                                        formErrors?.businessCategory !==
                                                        ''
                                                    }
                                                    value={category}
                                                >
                                                    <option>
                                                        Select business industry
                                                    </option>
                                                    {businessCategories.map(
                                                        (category) => (
                                                            <option
                                                                key={
                                                                    category.id
                                                                }
                                                                value={
                                                                    category.name
                                                                }
                                                            >
                                                                {category.name}
                                                            </option>
                                                        ),
                                                    )}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="form-group">
                                                <CustomLabel
                                                    className={
                                                        styles.customFieldLabel
                                                    }
                                                    label="Number of employees"
                                                    required
                                                />
                                                <CustomInput
                                                    required={true}
                                                    type="number"
                                                    placeholder="Eg: 250"
                                                    name="Number of employees"
                                                    value={employeesCount}
                                                    onChange={
                                                        handleEmployeesCountChange
                                                    }
                                                    min={0}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </>
                                )}
                            </Row>
                        ) : (
                            <>
                                <div>
                                    <div className={styles.policy_form_section}>
                                        <Form>
                                            <Row>
                                                <Col lg={6}>
                                                    <Form.Group className="form-group">
                                                        <CustomLabel
                                                            className={
                                                                styles.customFieldLabel
                                                            }
                                                            label="Legal Name for your Business"
                                                        />
                                                        <CustomInput
                                                            required={true}
                                                            type="text"
                                                            placeholder="Eg: Covrzy"
                                                            name="Legal Name for your Business"
                                                            value={legalName}
                                                            onChange={
                                                                handleLegalNameChange
                                                            }
                                                            isInvalid={
                                                                formErrors &&
                                                                formErrors.legalName &&
                                                                formErrors?.legalName !==
                                                                ''
                                                            }
                                                        />
                                                        {formErrors &&
                                                            formErrors.legalName &&
                                                            formErrors?.legalName !==
                                                            '' && (
                                                                <div className="text-error">
                                                                    {
                                                                        formErrors.legalName
                                                                    }
                                                                </div>
                                                            )}
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={6}>
                                                    <Form.Group className="form-group">
                                                        <CustomLabel
                                                            className={
                                                                styles.customFieldLabel
                                                            }
                                                            label="Address of your Business"
                                                        />
                                                        <CustomInput
                                                            required={true}
                                                            type="text"
                                                            placeholder="Eg:  Unit A506, Carlton Towers, 560008"
                                                            name="Address of your Business"
                                                            value={address}
                                                            onChange={
                                                                addressHandleChange
                                                            }
                                                            isInvalid={
                                                                formErrors &&
                                                                formErrors.address &&
                                                                formErrors?.address !==
                                                                ''
                                                            }
                                                        />
                                                        {formErrors &&
                                                            formErrors.address &&
                                                            formErrors?.address !==
                                                            '' && (
                                                                <div className="text-error">
                                                                    {
                                                                        formErrors.address
                                                                    }
                                                                </div>
                                                            )}
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={6}>
                                                    <Form.Group className="form-group">
                                                        <CustomLabel
                                                            className={
                                                                styles.customFieldLabel
                                                            }
                                                            label="Your Business Industry"
                                                        />
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            onChange={(e) =>
                                                                categoryHandleClick(
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            isInvalid={
                                                                formErrors &&
                                                                formErrors.businessCategory &&
                                                                formErrors?.businessCategory !==
                                                                ''
                                                            }
                                                            value={category}
                                                        >
                                                            <option>
                                                                Select business
                                                                industry
                                                            </option>
                                                            {businessCategories.map(
                                                                (category) => (
                                                                    <option
                                                                        key={
                                                                            category.id
                                                                        }
                                                                        value={
                                                                            category.name
                                                                        }
                                                                    >
                                                                        {
                                                                            category.name
                                                                        }
                                                                    </option>
                                                                ),
                                                            )}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={6}>
                                                    <Form.Group className="form-group">
                                                        <CustomLabel
                                                            className={
                                                                styles.customFieldLabel
                                                            }
                                                            label="Number of employees"
                                                        />
                                                        <CustomInput
                                                            required={true}
                                                            type="number"
                                                            placeholder="Eg: 250"
                                                            name="Number of employees"
                                                            value={
                                                                employeesCount
                                                            }
                                                            onChange={
                                                                handleEmployeesCountChange
                                                            }
                                                            min={0}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={6}>
                                                    <Form.Group className="form-group">
                                                        <CustomLabel
                                                            className={
                                                                styles.customFieldLabel
                                                            }
                                                            label="Company Turnover"
                                                            required
                                                        />
                                                        <Form.Select
                                                            name="annualTurnover"
                                                            value={
                                                                annualTurnover
                                                            }
                                                            onChange={(
                                                                e: any,
                                                            ) => {
                                                                setAnnualTurnover(
                                                                    e.target
                                                                        .value,
                                                                );
                                                            }}
                                                        >
                                                            {annualTurnover && (
                                                                <option
                                                                    value={
                                                                        annualTurnover
                                                                    }
                                                                >
                                                                    {
                                                                        annualTurnover
                                                                    }
                                                                </option>
                                                            )}
                                                            {annualTurnoverOptions.map(
                                                                (option) => (
                                                                    <option
                                                                        key={
                                                                            option
                                                                        }
                                                                        value={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </option>
                                                                ),
                                                            )}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </Row>

                <div className={styles.gst_txt}>
                    <p>Kindly check the GST information. If any discrepancy kindly <span>request call back</span> </p>
                </div>

                {loading && <FullPageSpinner />}
                {loading && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            background: 'rgba(0, 0, 0, 0.2)', // Adjust the opacity and color as needed
                            zIndex: 9999,
                        }}
                    />
                )}
            </div>
            <div className={`footer-buttons ${styles.custom_footerButtons}`}>
                {props.index !== 2 ? (
                    <div
                        className="footer-back-button"
                        onClick={() => {
                            if (props.index === 0) {
                                navigate('/quote/insurance-packages');
                            } else {
                                props.setIndex(props.index - 1);
                            }
                        }}
                    >
                        <img
                            src={ArrowBack}
                            alt=""
                            style={{ cursor: 'pointer' }}
                            className={styles.desktop_arrow}
                        />
                        <img
                            src={MobileArrowBack}
                            alt=""
                            style={{ cursor: 'pointer' }}
                            className={styles.mobile_arrow}
                        />
                    </div>
                ) : (
                    <div></div>
                )}
                {props.index !== 3 && (
                    <CustomButton
                        className={`next-button ${styles.custom_nextButton}`}
                        disabled={props.buttonDisable}
                        type="Submit"
                        buttonTitle={
                            <div className={styles.arrow_svg}>
                                Next
                                <span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <circle
                                            cx="12"
                                            cy="12"
                                            r="12"
                                            transform="rotate(-180 12 12)"
                                            fill="white"
                                        />
                                        <g clip-path="url(#clip0_1627_17530)">
                                            <path
                                                d="M13.6302 12.0004L9.91766 8.28794L10.9782 7.22744L15.7512 12.0004L10.9782 16.7734L9.91766 15.7129L13.6302 12.0004Z"
                                                fill="#774AD9"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1627_17530">
                                                <rect
                                                    width="18"
                                                    height="18"
                                                    fill="white"
                                                    transform="matrix(-1 0 0 -1 21 21)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                            </div>
                        }
                        onClick={handleNextButtonClick}
                    />
                )}
            </div>
        </>
    );
}

export default AboutBusiness;
