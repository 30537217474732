import { call, put, select } from 'redux-saga/effects';
import axios from '../../axiosConfig';
import {
    LIABILITY_QUOTE_API_REQUEST_ERROR_RESPONSE,
    LIABILITY_QUOTE_API_REQUEST_SUCCESS_RESPONSE,
} from '../Actions/QuoteAction';
import { SHOW_ERROR_HANDLER_MODAL, TOAST_SHOW } from '../Actions/ToastAction';
import { v4 as uuidv4 } from 'uuid';
import { IReduxState } from '../../utils/types';
import { getErrorMessage } from '../../Helper/commonFunction';
import { generatePolicyStartAndEndDates } from '../../utils/DateUtil';

interface IProducts {
    payload: Map<string, string>;
}

export function* GetQuoteSaga(actions: IProducts): unknown {
    try {
        const correlationId = uuidv4();
        const coverSIMap = actions.payload;

        const { policyStartDate, policyEndDate } = generatePolicyStartAndEndDates();

        const { selectedCard } = yield select(
            (state: IReduxState) => state.PurchaseQuote,
        );

        const requestBody = {
            correlationId,
            policyStartDate: policyStartDate,
            policyEndDate: policyEndDate,
            risks: covertSelectedProductsToRisks(selectedCard, coverSIMap),
        };

        const response = yield call(
            axios.post,
            'v1/liability/quotes',
            requestBody,
        );

        const { success, data } = response.data;

        if (success) {
            yield put({
                type: LIABILITY_QUOTE_API_REQUEST_SUCCESS_RESPONSE,
                payload: data,
            });

            yield put({
                type: TOAST_SHOW,
                payload: {
                    message: 'Quote generated',
                    severity: 'success',
                    show: true,
                },
            });
        } else {
            yield put({ type: LIABILITY_QUOTE_API_REQUEST_ERROR_RESPONSE });
            yield put({
                type: TOAST_SHOW,
                payload: {
                    message: 'Something went wrong',
                    severity: 'danger',
                    show: true,
                },
            });
        }
    } catch (error: any) {
        console.log('Error in GetQuoteSaga:', error); // Log error
        yield put({ type: LIABILITY_QUOTE_API_REQUEST_ERROR_RESPONSE });
        yield put({
            type: SHOW_ERROR_HANDLER_MODAL,
            payload: {
                errorResponse: getErrorMessage(error.response),
                openModal: true,
            },
        });
    }
}

function covertSelectedProductsToRisks(
    selectedProducts: any[],
    coverSIMap: Map<string, string>,
): any {
    const risks: any[] = [];

    selectedProducts.forEach((data) => {
        const index = risks.findIndex(
            (risk) => risk.riskName === data.riskName,
        );

        // for (const [key, value] of Array.from(coverSIMap.entries())) {
        //     (value as any).covers.forEach((cover: any) => {
        //         if (cover.coverName === 'D And O Basic Cover') {
        //             cover.coverName = 'D And O Basic Cover And Company securities With EPLI'; // Update the coverName
        //         } else if (cover.coverName === 'D And O Basic Cover And Company securities With EPLI') {
        //             cover.coverName = 'D And O Basic Cover'; // Update the coverName
        //         }
        //     });
        //     coverSI.set(key, value); // Update the Map with the new value
        // }


        if (index === -1) {
            const coverSI = coverSIMap.get(data.name) || coverSIMap.get(data.riskName);
            const coverSIObject = coverSI || JSON.parse(data.coverSI);

            // coverSI !== null ? JSON.parse(data.coverSI) : null;
            risks.push({
                riskName: data.riskName,
                covers: typeof coverSIObject === 'number' ? [
                    {
                        coverName: data.coverName,
                        coverSI: coverSIObject,
                    },
                ] : coverSIObject,
            });
        } else {
            const coverSI = coverSIMap.get(data.coverName);
            const coverSIObject = coverSI || JSON.parse(data.coverSI);
            // const coverSIObject =
            //     coverSI !== null ? JSON.parse(data.coverSI) : null;
            risks[index].covers.push({
                coverName: data.coverName,
                coverSI: coverSIObject,
            });
        }
    });

    return risks;
}
