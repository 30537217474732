import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from '../../utils/types';
import Sidebar from '../../components/Sidebar';
import QuoteCustomStepper from '../../common/QuoteCustomStepper';
import UserDetails from './AuthComponent/UserDetails';
import stepperImg from '../../assets/images/stepperimg.svg';
import sidebarIcon0 from '../../assets/images/sideBarImage1.svg';
import sidebarIcon1 from '../../assets/images/sideBarImage1.svg';
import sidebarIcon2 from '../../assets/images/sideBarImage2.svg';
import sidebarIcon3 from '../../assets/images/sideBarImage1.svg';
import sidebarIcon4 from '../../assets/images/additional_policyimg.svg';
import sidebarIcon5 from '../../assets/images/sidebarIcon5.svg';
import RecommendationComponent from './Recommendation';
import AboutBusiness from './AboutBusiness/AboutBusiness';
import CheckoutComponent from './Checkout';
import { UpdateUserProfileAction } from '../../Redux/Actions/UserProfileAction';
import {
    GST_API_SUCCESS_RESPONSE,
    SaveAboutBusinessAction,
    SaveSelectedProductsAction,
} from '../../Redux/Actions/CustomerInformationAction';
import { RECOMMENDATION_API_SUCCESS_RESPONSE } from '../../Redux/Actions/RecommendationAction';
import { isObjectEmpty } from '../../Helper/commonFunction';
import { LIABILITY_QUOTE_API_REQUEST_SUCCESS_RESPONSE } from '../../Redux/Actions/QuoteAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { INITIATE_PURCHASE_POLICY_SUCCESS } from '../../Redux/Actions/PolicyInputAction';
import style from './style.module.scss';
import CheckoutComponent2 from './Checkout2';
import ProposalComponent from './ProposalComponent';
import AdditionalDetailsComponent from './AdditionalDetailsComponent';
import AdditionalDetailsComponent2 from './AdditionalDetailsComponent2';
import Congratulations from './Congratulations';
import { base64Decode } from '../../utils/Base64Util';

interface AboutBusinessProps {
    buttonClicks: boolean;
    setSubmitButtonClick: any;
    formFilled: any;
    setFormFilled: any;
    buttonDisable: boolean;
    setButtonDisable: any;
    setIndex: any;
    setButtonClick: any;
    index: number;
    businessData: any;
}

export interface PersonalDetail {
    firstName: string;
    lastName: string;
    dob: string;
    pan: string;
}

export interface ContactDetail {
    email: string;
    phone: string;
}

export interface AddressDetail {
    address: string;
    pincode: string;
    country: string;
    state: string;
    city: string;
}

export interface InsuredDetail {
    insuredFirstName: string;
    insuredLastName: string;
    insuredDOB: string;
    insuredAge: string;
    insuredGender: string;
    insuredRelation: string;
}

export interface PolicyDetail {
    policyStartDate: string;
    setPolicyStartDate: (date: string) => void;
    policyEndDate: string;
    setPolicyEndDate: (date: string) => void;
    isLitigation: boolean;
    setIsLitigation: (isLitigation: boolean) => void;
    litigationDate: string;
    setLitigationDate: (date: string) => void;
}

export interface IndexManagement {
    index: number;
    setIndex: (index: number) => void;
}

function QuoteContainer() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [index, setIndex] = useState(0);
    const [shouldOverrideIndex, setShouldOverrideIndex] = useState(true);
    const [buttonClick, setButtonClick] = useState(false);
    const [submitButtonClick, setSubmitButtonClick] = useState(false);
    const [formFilled, setFormFilled] = useState({
        gstin: false,
        legalName: false,
        annualTurnover: false,
        address: false,
        category: false,
        fundingAmount: false,
        employeesCount: false,
    });
    const [buttonDisable, setButtonDisable] = useState(false);
    const [businessData, setBusinessData] = useState(null);
    const [recommendationData, setRecommendationData] = useState<any>(null);
    const [selectedAddOns, setSelectedAddOns] = useState<any[]>([]);

    const { paymentRetry } = useSelector((state: IReduxState) => state.Payment);
    const userSession = useSelector((state: IReduxState) => state.UserSession);
    const { quoteResponse } = useSelector(
        (state: IReduxState) => state.LiabilityQuote,
    );
    const [policyStartDate, setPolicyStartDate] = useState<string>('');
    const [policyEndDate, setPolicyEndDate] = useState<string>('');
    const [isLitigation, setIsLitigation] = useState<boolean>(false);
    const [litigationDate, setLitigationDate] = useState<string>('');

    const [personalDetail, setPersonalDetail] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        pan: '',
    });

    const [contactDetail, setContactDetail] = useState({
        email: '',
        phone: '',
    });

    const [addressDetail, setAddressDetail] = useState({
        address: '',
        pincode: '',
        country: '',
        state: '',
        city: '',
    });

    const [insuredDetail, setInsuredDetail] = useState({
        insuredFirstName: '',
        insuredLastName: '',
        insuredDOB: '',
        insuredAge: '',
        insuredGender: '',
        insuredRelation: '',
    });

    useEffect(() => {
        if (!shouldOverrideIndex) return; // Skip if manual override is in place

        const queryParams = new URLSearchParams(location.search);
        const payload = queryParams.get('payload');

        let savedIndex = 0;
        const data = userSession.userSessionData;
        let previousStepPresent = false;

        if (payload) {
            const decodedPayload = base64Decode(payload);
            if (decodedPayload && decodedPayload.kyc === 'initiated') {
                setIndex(6);
                setShouldOverrideIndex(false); // Prevent further overrides
                return;
            }
        }

        if (!data) {
            return;
        }

        if (!isObjectEmpty(data.userData)) {
            savedIndex = 1;
            if (data.policies && data.businessCategory) {
                dispatch(
                    SaveSelectedProductsAction({
                        policies: data.policies,
                        businessCategory: data.businessCategory,
                    }),
                );
            }
            previousStepPresent = true;
        }

        if (!previousStepPresent) {
            setIndex(savedIndex);
            return;
        }

        previousStepPresent = false;

        if (!isObjectEmpty(data.businessData) || !isObjectEmpty(data.gstData)) {
            //saving profile data
            const formData = new FormData();
            formData.append('first_name', data.userData.firstName);
            formData.append('last_name', data.userData.lastName);
            formData.append('email', data.userData.email);
            dispatch(UpdateUserProfileAction(formData));
            setBusinessData(data.businessData);

            savedIndex = 2;

            if (!isObjectEmpty(data.gstData)) {
                dispatch({
                    type: GST_API_SUCCESS_RESPONSE,
                    payload: data.gstData,
                });

                savedIndex = 1;
                savedIndex = 1;
                savedIndex = 1;
                savedIndex = 1;
                savedIndex = 1;
            }

            previousStepPresent = true;
        }

        if (!previousStepPresent) {
            setIndex(savedIndex);
            return;
        }

        previousStepPresent = false;

        if (!isObjectEmpty(data.recommendationResponse)) {
            //saving business data
            const formData = new FormData();
            formData.append('address', data.businessData.address);
            dispatch(SaveAboutBusinessAction(data.businessData));
            dispatch(UpdateUserProfileAction(formData));

            // saving recommendationResponse
            dispatch({
                type: RECOMMENDATION_API_SUCCESS_RESPONSE,
                payload: data.recommendationResponse,
            });

            if (data.userSelectedData) {
                setRecommendationData({
                    products: data.userSelectedData?.products,
                    policyEndDate: data.userSelectedData?.policyEndDate,
                    policyStartDate: data.userSelectedData?.policyStartDate,
                });
            }

            savedIndex = 2;
            previousStepPresent = true;
        }

        if (!previousStepPresent) {
            setIndex(savedIndex);
            return;
        }

        previousStepPresent = false;

        if (!isObjectEmpty(data.quoteResponse)) {
            dispatch({
                type: LIABILITY_QUOTE_API_REQUEST_SUCCESS_RESPONSE,
                payload: data.quoteResponse,
            });

            setIndex(3);
            previousStepPresent = true;
        }

        if (!previousStepPresent) {
            setIndex(savedIndex);
            return;
        }

        previousStepPresent = false;

        if (!isObjectEmpty(data.paymentResponse)) {
            localStorage.setItem('order', data.paymentResponse.orderId);
            navigate(
                '/quote/policy-purchase/payment-success/' +
                data.paymentResponse.receiptId,
            );
            previousStepPresent = true;
        }

        if (!previousStepPresent) {
            setIndex(savedIndex);
            return;
        }

        previousStepPresent = false;

        if (!isObjectEmpty(data.purchasedPolicyData)) {
            dispatch({
                type: INITIATE_PURCHASE_POLICY_SUCCESS,
                payload: data.purchasedPolicyData,
            });
            navigate('/quote/policy-certificate');
            previousStepPresent = true;
        }

        if (!previousStepPresent) {
            setIndex(savedIndex);
            return;
        }

        previousStepPresent = false;

        setIndex(savedIndex);
    }, []);

    useEffect(() => {
        if (paymentRetry) {
            setIndex(5);
        }
    }, [paymentRetry]);

    const setCombinedButtonClicks = (click: boolean) => {
        setButtonClick(click);
        setSubmitButtonClick(click);
    };

    function quoteSwitch() {
        switch (index) {
            case 0:
                return (
                    <>
                        <AboutBusiness
                            buttonClicks={submitButtonClick}
                            setSubmitButtonClick={setCombinedButtonClicks}
                            formFilled={formFilled}
                            setFormFilled={setFormFilled}
                            buttonDisable={buttonDisable}
                            setButtonDisable={setButtonDisable}
                            setIndex={setIndex}
                            index={index}
                            setButtonClick={setButtonClick}
                            businessData={businessData}
                        />
                    </>
                );

            case 1:
                return (
                    <>
                        <AboutBusiness
                            buttonClicks={submitButtonClick}
                            setSubmitButtonClick={setCombinedButtonClicks}
                            formFilled={formFilled}
                            setFormFilled={setFormFilled}
                            buttonDisable={buttonDisable}
                            setButtonDisable={setButtonDisable}
                            setIndex={setIndex}
                            index={index}
                            setButtonClick={setButtonClick}
                            businessData={businessData}
                        />
                    </>
                );
            case 2:
                return (
                    <RecommendationComponent
                        buttonClicked={buttonClick}
                        setIndex={setIndex}
                        setButtonClick={setButtonClick}
                        setSubmitButtonClicks={setSubmitButtonClick}
                        index={index}
                        userSelectedData={recommendationData}
                    />
                );

            case 3:
                return <CheckoutComponent index={index} setIndex={setIndex} />;

            case 4:
                return (
                    <CheckoutComponent2
                        index={index}
                        setIndex={setIndex}
                        selectedAddOns={selectedAddOns}
                        setSelectedAddOns={setSelectedAddOns}
                    />
                );
            case 5:
                // return <ProposalComponent index={index} setIndex={setIndex} />;
                return (
                    <ProposalComponent
                        index={index}
                        setIndex={setIndex}
                        selectedAddOns={selectedAddOns}
                        quoteResponse={quoteResponse}
                        recommendationData={recommendationData}
                    />
                );
            case 6:
                return (
                    <AdditionalDetailsComponent
                        index={index}
                        setIndex={setIndex}
                        policyStartDate={policyStartDate}
                        setPolicyStartDate={setPolicyStartDate}
                        policyEndDate={policyEndDate}
                        setPolicyEndDate={setPolicyEndDate}
                        isLitigation={isLitigation}
                        setIsLitigation={setIsLitigation}
                        litigationDate={litigationDate}
                        setLitigationDate={setLitigationDate}
                        personalDetail={personalDetail}
                        setPersonalDetail={setPersonalDetail}
                        addressDetail={addressDetail}
                        setAddressDetail={setAddressDetail}
                        contactDetail={contactDetail}
                        setContactDetail={setContactDetail}
                        insuredDetail={insuredDetail}
                        setInsuredDetail={setInsuredDetail}
                    />
                );
            case 7:
                return (
                    <AdditionalDetailsComponent2
                        index={index}
                        setIndex={setIndex}
                        policyStartDate={policyStartDate}
                        setPolicyStartDate={setPolicyStartDate}
                        policyEndDate={policyEndDate}
                        setPolicyEndDate={setPolicyEndDate}
                        isLitigation={isLitigation}
                        setIsLitigation={setIsLitigation}
                        litigationDate={litigationDate}
                        setLitigationDate={setLitigationDate}
                        personalDetail={personalDetail}
                        setPersonalDetail={setPersonalDetail}
                        addressDetail={addressDetail}
                        setAddressDetail={setAddressDetail}
                        contactDetail={contactDetail}
                        setContactDetail={setContactDetail}
                        insuredDetail={insuredDetail}
                        setInsuredDetail={setInsuredDetail}
                    />
                );
            case 8:
                return <Congratulations />;
            default:
                break;
        }
    }

    function sidebarImageSwitch() {
        switch (index) {
            case 0:
                return sidebarIcon0;
            case 1:
                return sidebarIcon1;
            case 2:
                return sidebarIcon2;
            case 3:
                return sidebarIcon3;
            case 6:

                return sidebarIcon4;
            case 7:
                return sidebarIcon5;
            default:
                return sidebarIcon0;
        }
    }

    return index < 8 ? (
        <section className="otp-login-sec quote-bg ">
            <Sidebar
                sidebarHeader={
                    index === 7
                        ? "You're almost there!"
                        : 'Begin Your application'
                }
                sideBarDesc={
                    index === 7
                        ? 'Take a moment to review your details and get ready to receive the final policy copy in the next step.'
                        : 'We will need few more details before we display the prices'
                }
                bannerImg={sidebarImageSwitch()}
                showEstimate={index >= 3 && index < 6}
                index={index}
                setIndex={setIndex}
            />
            <div className="quote-container ">
                <div
                    className={`quote-login-container ${style.quoteContainer}`}
                >
                    <div className="stepper-container">
                        <QuoteCustomStepper step={index} />
                    </div>
                    {(index === 0 || index === 1) && (
                        <div className={style.step_img}>
                            <img src={stepperImg} alt="" />
                        </div>
                    )}
                    {quoteSwitch()}
                </div>
            </div>
            :
        </section>
    ) : (
        <section
            className="otp-login-sec quote-bg "
            style={{ justifyContent: 'center' }}
        >
            <Congratulations />
        </section>
    );
}

export default QuoteContainer;
