import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InsurerLogoComponent from '../../InsurerLogo';
import ICICLogo from '../../../assets/images/iciciLombard.svg';
import PaymentInfoHeader from '../PaymentInfoHeader';
import Style from './style.module.scss';
import PaymentInfoCard from '../PaymentInfoCard';
import { IReduxState } from '../../../utils/types';
import { NumberFormat } from '../../../common/NumberFormat';
import { formatAmount } from '../../../common/FormatAmount';
import PaymentReceipt from '../PaymentReceipt/PaymentReceipt';
import { isObjectEmpty } from '../../../Helper/commonFunction';
import {
    formatDateString,
    getCoverData,
} from '../../../pages/V2Quote/Checkout';
import { UpdateUserSessionAction } from '../../../Redux/Actions/SessionAction';
import greenCheck from '../../../assets/images/greenCheck.svg';
import redCheck from '../../../assets/images/redCheck.svg';
import { useNavigate } from 'react-router-dom';
import { base64Encode } from '../../../utils/Base64Util';
import { data } from '../../../utils/QuoteData/ProductsDetails';

interface PaymentDetailsCardProps {
    coverageLogo: any;
    success: boolean;
}
interface cover {
    coverName: string;
    coverSI: string;
    premium: string;
}

function PaymentDetailsCard({
    coverageLogo,
    success,
}: PaymentDetailsCardProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [coverageCards, setCoverageCards] = useState<any[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);

    const { selectedCard } = useSelector(
        (state: IReduxState) => state.PurchaseQuote,
    );

    const { policyStartDate, recommendationResponse } = useSelector(
        (state: IReduxState) => state.Recommendations,
    );

    const { paymentResponse } = useSelector(
        (state: IReduxState) => state.Payment,
    );

    const { quoteResponse } = useSelector(
        (state: IReduxState) => state.LiabilityQuote,
    );

    const { gstData, aboutBusiness } = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );

    const userSession = useSelector((state: IReduxState) => state.UserSession);

    const coversData = data[0].cards?.map((data) => ({
        name: data.name?.trim(),
        risk: data.risks[0].riskName,
        coverName: data.risks[0].coverName
    }));

    useEffect(() => {
        if (!isObjectEmpty(quoteResponse)) {
            const mappedCoverageCards = quoteResponse.risks.flatMap(
                (risk: any) => risk.covers,
            );
            const updatedCoverageCards: cover[] = mappedCoverageCards.map(
                (cover: cover) => {
                    return getCoverData(
                        cover.coverName,
                        cover.coverSI,
                        cover.premium,
                    );
                },
            );

            setCoverageCards(updatedCoverageCards);


            const selectedPlans = quoteResponse?.risks?.map((data: any) =>
                coversData?.find((record: any) => record?.risk === data?.riskName && record?.coverName === data?.covers?.[0]?.coverName)?.name ?? data?.riskName
            )

            setSelectedProducts(selectedPlans);
        }
    }, [selectedCard, quoteResponse]);

    useEffect(() => {
        if (!isObjectEmpty(paymentResponse) && success) {
            dispatch(
                UpdateUserSessionAction({
                    id: userSession.id, // always add for params
                    nextSessionState: 'payment_completed',
                    paymentId: paymentResponse.payment_id,
                    userSessionData: {
                        paymentResponse: paymentResponse,
                    },
                }),
            );
        }
    }, [paymentResponse]);

    return (
        <>
            <div className={Style.main}>
                <div className={Style.header}>
                    <InsurerLogoComponent image={ICICLogo} paymentType={true} />
                    <PaymentInfoHeader
                        success={success}
                        header="Payment Information"
                        downloadReceipt={
                            <PaymentReceipt
                                receiptId={paymentResponse.receiptId}
                                orderDate={policyStartDate}
                                orderNumber={paymentResponse.orderId}
                                gst={gstData.gstin}
                                data={aboutBusiness}
                                invoiceDate={paymentResponse.paymentDate}
                                transactionType={paymentResponse.cardType}
                                currency={paymentResponse.currency}
                                products={coverageCards}
                                price={paymentResponse.basicAmount}
                                tax={paymentResponse.tax}
                                total={paymentResponse.grossAmount}
                                address={aboutBusiness.address}
                            />
                        }
                    />
                </div>
                <div className={Style.content}>
                    <div className={Style.plan_section}>
                        <div className={Style.plan_heading}>
                            <h6>Coverage Plans</h6>
                            {!success && (
                                <p className={Style.asterisk}>Payment Failed</p>
                            )}
                        </div>

                        <div className={Style.product_tab_section}>
                            {
                                selectedProducts?.map((product, index) =>
                                    <div className={Style.product_tab} key={index}>
                                        <div>
                                            <img
                                                src={success ? greenCheck : redCheck}
                                                alt=""
                                            />
                                        </div>
                                        <h6>{product}</h6>
                                    </div>
                                )
                            }
                            {/* <div className={Style.product_tab}>
                                <div>
                                    <img
                                        src={success ? greenCheck : redCheck}
                                        alt=""
                                    />
                                </div>
                                <h6>Cyber Liability</h6>
                            </div>

                            <div className={Style.product_tab}>
                                <div>
                                    <img
                                        src={success ? greenCheck : redCheck}
                                        alt=""
                                    />
                                </div>
                                <h6>
                                    Directors and Officers Liability Insurance
                                </h6>
                            </div>

                            <div className={Style.product_tab}>
                                <div>
                                    <img
                                        src={success ? greenCheck : redCheck}
                                        alt=""
                                    />
                                </div>
                                <h6>CGL Product Liability Insurance</h6>
                            </div> */}
                        </div>
                    </div>

                    {/* <PaymentInfoCard
                    title="Coverage plans & Sum Insured"
                    contents={coverageCards.map(({ name, coverSI }, index) => ({
                        key: index,
                        title: name,
                        value: formatAmount(coverSI),
                    }))}
                /> */}

                    {/* <PaymentInfoCard
                        title="Coverage Summary"
                        contents={[
                            {
                                title: 'Effective Date',
                                value: formatDateString(policyStartDate),
                            },
                            { title: 'Coverage Term', value: '1 Year' },
                            {
                                title: 'Payment Method',
                                value: paymentResponse.cardType,
                            },
                        ]}
                    /> */}
                    {success && <PaymentInfoCard
                        title="Payment Confirmation"
                        contents={[
                            {
                                title: 'Transaction ID',
                                value: '#' + paymentResponse.transactionId,
                            },

                            {
                                title: 'Total Premium Amount',
                                value: NumberFormat(
                                    paymentResponse.basicAmount,
                                ),
                            },
                            {
                                title: 'Tax',
                                value: NumberFormat(paymentResponse.tax),
                            },
                            {
                                title: 'Payment Amount',
                                value: NumberFormat(
                                    paymentResponse.grossAmount,
                                ),
                            },
                        ]}
                    />}
                </div>
            </div>
            {success && (
                <div className={Style.kyc_section}>
                    <div>
                        <p>
                            To get your policy booked, kindly fill in the KYC
                            details
                        </p>
                    </div>
                    <div className={Style.kyc_btn}>
                        <button
                            className={Style.btn}
                            onClick={() => {
                                const payload = base64Encode({ kyc: 'initiated' });
                                navigate(`/quote/info?payload=${payload}`);
                            }}
                        >
                            Proceed to KYC
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default PaymentDetailsCard;
