import { useTranslation } from "react-i18next";
import style from "./style.module.scss"
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import check from '../../../assets/images/success_check.png';
import confit from '../../../assets/images/confit.gif';
import { Download } from "react-bootstrap-icons";
function Congratulations() {
    const { t: translate } = useTranslation();

    const navigate = useNavigate();

    const BETA_LINK = process.env.REACT_APP_BETA_URL


    return (
        <div className={style.congratulationsWrp}>
            <div className={style.congratulationsImg}>
                <div className={style.animBox}>
                    <div className={style.confit}>
                        <img src={confit} alt="check" />
                    </div>
                    <div className={style.check}>
                        <img src={check} alt="check" />
                    </div>
                </div>
            </div>
            <div className={style.congratulationTitle}>
                <h2>
                    Congratulations, Sudeep!
                    <br />
                    <span>You’ve successfully secured company name</span>
                </h2>
                <p>
                    Your policy certificate is now available to download.<br />
                    We will also email the policy to you at username@domain.com
                </p>

                <div className={style.securedBtn}>
                    <Button className={style.policyBtn}><Download />Policy Copy</Button>
                    <Button className={style.paymentBtn}><Download />Payment Invoice</Button>
                </div>

                {/* <div>
                    <p>If you have any questions, feel free to reach us any time.</p>
                    <Button>Go to Dashboard</Button>
                </div> */}
                {/* <a
                href={require('../../../assets/documents/sample.pdf')}
                download="Insurance Policy"
            >
                <Button
                    className={style.downloadBtn}
                    onClick={() => handleDownloadDocument()}
                >
                    {translate(
                        'PAGE_COMPONENTS.BUY_INSURANCE.STEP_15.DOWNLOAD_POLICY_BUTTON',
                    )}
                    <Download />
                </Button>
            </a> */}
                <div className={style.note}>
                    <p>
                        {translate(
                            'PAGE_COMPONENTS.BUY_INSURANCE.STEP_15.DESCRIPTION.3',
                        )}
                    </p>
                </div>
                <Button
                    className={style.dashboardBtn}
                    onClick={() => {
                        if (BETA_LINK) {
                            window.location.href = BETA_LINK + "/user/dashboard"
                        } else {
                            navigate('/')
                        }
                    }}
                >
                    {translate(
                        'PAGE_COMPONENTS.BUY_INSURANCE.STEP_15.GO_TO_DASHBOARD',
                    )}
                </Button>
            </div>
        </div>
    );
}

export default Congratulations;
