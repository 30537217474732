import React, { useState } from 'react';
import style from './style.module.scss';
import { Container, Col, Row } from 'react-bootstrap';
import heroimage from '../../assets/images/libility_img.png';
import heromobileimage from '../../assets/images/libility-mobile-img.svg';
import fire from '../../../assets/images/fire.png';
import { TelephoneInbound } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import wind from '../../assets/images/wind.png';
import flood from '../../assets/images/flooded-house.png';
import thief from '../../assets/images/thief.png';
import loss from '../../assets/images/chat-arrow-down.png';
import hand from '../../assets/images/black-hand.png';
import explosion from '../../assets/images/tree.png';
import spill from '../../assets/images/spill.png';
import bomb from '../../assets/images/bomb.png';
import accident from '../../assets/images/accident.svg';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from '../../utils/types';
// import RequestCallbackFormModal from '../RequestCallbackFormModal';
import { useTranslation } from 'react-i18next';
import CustomLoginModal from '../CustomLoginModal';
import RequestCallbackFormModal from '../../common/RequestCallbackFormModal';
// import { openPopupRequestCallback } from '../../Redux/Actions/PopupActions';

const LiabilityHero = () => {
    const navigate = useNavigate();

    /**
     * Dispatches an action to open the request callback pop-up.
     */
    const dispatch = useDispatch();

    const [showRequestCallbackFormModal, setShowRequestCallbackFormModal] = useState(false);

    /**
     * Handles opening the request callback pop-up.
     * Dispatches the openPopupRequestCallback action.
     */
    const handleRequestCallbackOpen = () => {
        // Dispatch the openPopupRequestCallback action to open the request callback pop-up
        setShowRequestCallbackFormModal(true);
    };

    /**
     * Handles closing the request callback pop-up.
     * Sets the showRequestCallbackFormModal state to false.
     */
    const handleRequestCallbackClose = () => {
        // Set the showRequestCallbackFormModal state to false to close the request callback pop-up
        setShowRequestCallbackFormModal(false);
    };

    // Retrieves the AuthState from the Redux store.
    const AuthState = useSelector((state: IReduxState) => state.Auth);

    // Retrieves the UserSession from the Redux store.
    const UserSession = useSelector((state: IReduxState) => state.UserSession);

    // State variable to track whether the login modal should be shown or hidden.
    const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

    // Retrieves the translation function from the i18next hook.
    const { t: translate } = useTranslation();

    // const SMEDetails = useSelector((state: IReduxState) => state.SMEDetails);

    /**
     * Handles the click on the "Get Insured" button.
     *
     * If the user is logged in and has a valid session, it navigates to the quote
     * information page. Otherwise, it shows the login modal.
     */
    const handleGetInsuredClick = () => {

        if (
            // Check if the user is logged in
            AuthState.isLogin &&
            // Check if the user session is valid
            UserSession?.user
        ) {
            // Navigate to the quote information page
            navigate('/quote/info');
        } else {
            // Show the login modal
            setShowLoginModal(true);
        }
    };

    return (
        <div className={style.heroSection}>
            <Container className={style.herocontainer}>
                <Row className={style.colomRevers}>
                    <Col lg={6} md={12} sm={12}>
                        <div className={style.protectSection}>
                            <div className={style.protectText}>
                                <h2>
                                    {translate(
                                        'Insurance Plans Customised For Your Business',
                                    )}
                                </h2>
                                <p>
                                    Discover tailored insurance options for your business.
                                    Explore, filter, and request quotes effortlessly.
                                </p>
                            </div>
                            <div className={style.RequestBtn}>
                                <button
                                    className={style.RequestButton}
                                    onClick={handleRequestCallbackOpen}
                                >
                                    {' '}
                                    <span>
                                        {' '}
                                        <TelephoneInbound />
                                    </span>{' '}
                                    {translate(
                                        'PAGE_COMPONENTS.HOME_PAGE.HERO_BANNER.REQUEST_CALLBACK',
                                    )}
                                </button>
                                <button
                                    className={style.protectedButton}
                                    onClick={handleGetInsuredClick}
                                >
                                    {translate(
                                        'PAGE_COMPONENTS.HOME_PAGE.HERO_BANNER.GET_INSURED_NOW',
                                    )}
                                </button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                        <div className={style.heroImage}>
                            <img src={heroimage} alt="heroimage" className={style.herofirstimage}/>
                            <img src={heromobileimage} alt="heroimage" className={style.herosecondimage}/>
                        </div>
                    </Col>
                </Row>
            </Container>
            <CustomLoginModal showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} navigationAllowed={true} />
            <RequestCallbackFormModal showRequestCallbackFormModal={showRequestCallbackFormModal} setShowRequestCallbackFormModal={setShowRequestCallbackFormModal} />
        </div>
    );
};

export default LiabilityHero;
