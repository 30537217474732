import { useEffect, useState } from 'react';
import { Button, Container, Dropdown, Form, Nav, Navbar, Offcanvas } from 'react-bootstrap';
// import EditProfile from '../../assets/images/EditProfile.svg';
// import ChangePassword from '../../assets/images/ChangePassword.svg';
// import EditCompanyProfile from '../../assets/images/EditCompanyProfile.svg';
// import EditTeamSettings from '../../assets/images/EditTeamSettings.svg';
// import profileIcon from '../../assets/images/profile.svg';
// import LogoutIcon from '../../assets/images/LOGIN.png';
import EditProfile from '../../../assets/images/EditProfile.svg';
import ChangePassword from '../../../assets/images/ChangePassword.svg';
import EditCompanyProfile from '../../../assets/images/EditCompanyProfile.svg';
import EditTeamSettings from '../../../assets/images/EditTeamSettings.svg';
import profileIcon from '../../../assets/images/profile.svg';
import LogoutIcon from '../../../assets/images/logout.png';
import logo from "../../../assets/images/logo.png"
import xcircle from "../../../assets/images/x-circle.svg"
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from '../../../utils/types';
import { LogoutAction } from '../../../Redux/Actions/AuthActions';
import { DecryptData } from '../../CryptoJSToken';
import CustomButton from '../../Buttons/CustomButton';
import CustomLoginModal from '../../../components/CustomLoginModal';
// import useMSG90OTPWidget from './MSG90widgetfunction';
import style from "./style.module.scss";
import { CheckCircleFill, Globe } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import avtarImg from '../../../assets/images/Avatar.png';
import TalkToExpertModal from '../../../components/TalkToExpertModal';
import Language from '../../../assets/images/language_icon.svg';

const QuoteHeader = () => {

    const [userProfile, setUserProfile] = useState<string>('');
    const dispatch = useDispatch();
    // const { handleSubmit } = useMSG90OTPWidget();

    const AuthState = useSelector((state: IReduxState) => state.Auth);
    const userDetails = useSelector((state: IReduxState) => state.UserProfile);

    const user = userDetails.userProfile || DecryptData(AuthState.user);


    useEffect(() => {
        if (user?.profile) {
            const baseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
            const url = baseUrl + user?.profile;
            setUserProfile(url);
        } else setUserProfile('');
    }, [user, AuthState.isLogin]);

    const BETA_LINK = process.env.REACT_APP_BETA_URL

    const navigate = useNavigate();

    const [selectedLanguage, setSelectedLanguage] = useState('english');
    const languages = [
        {
            name: 'english',
            display: 'English',
        },
        {
            name: 'hindi',
            display: 'हिंदी',
        }
    ];

    const { t: translate } = useTranslation();

    const [continuePayment] = useState(false);
    const [failedPayment] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);

    // const Preference = useSelector((state: IReduxState) => state.Preference);

    // const { currentStep } = useSelector(
    //     (state: IReduxState) => state.SMEDetails,
    // );

    const handleLanguageSelect = (language: any) => {
        setSelectedLanguage(language);
        // dispatch(ChangeLanguageAction(language as keyof ELanguagesOptions));
    };

    // useLayoutEffect(() => {
    //     setSelectedLanguage(Preference?.language);
    // }, [Preference?.language]);


    const [show, setShow] = useState(false);

    // state to manage talk to expert modal
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleUserLogout = () => {
        dispatch(LogoutAction());
    }

    const handleNavigate = (route: string) => {
        if (BETA_LINK && window.location.origin === BETA_LINK) {
            window.location.href = BETA_LINK + route
        } else {
            navigate(route)
        }
    }

    const handleCustomNavigation = () => {
        if (window.location.pathname.includes('/quote')) {
            // trigger modal open
            setShowModal(true);
        } else {
            navigate('/')
        }
    }

    const handleTalkToExpertModalClose = () => {
        setShowModal(false);
        navigate('/')
    }

    return (
        <>
            <div className={style.headerSection}>
                <Navbar
                    expand="lg"
                    className={`bg-body-tertiary  ${style.navNavbar}`}
                >
                    <Container>
                        <Navbar.Brand
                            // href='/'
                            className={style.brandLogo}
                        >
                            <img
                                src={logo}
                                alt="logo"
                                onClick={handleCustomNavigation}
                            />
                        </Navbar.Brand>
                        {
                            !AuthState.isLogin ?
                                <>
                                    <div
                                        className={`d-flex d-lg-none gap-3 ${style.button}`}
                                    >
                                        <div>
                                            <Button
                                                className={style.loginBtn}
                                                onClick={() => {
                                                    // const isNavigationAllowed = false;
                                                    // handleSubmit(isNavigationAllowed);
                                                    setShowLoginModal(true);
                                                }}
                                            >
                                                {translate(
                                                    'LIABILITY_LAYOUT.HEADER.NORMAL_HEADER.LOGIN',
                                                )}
                                            </Button>
                                        </div>
                                        {/* <Navbar.Toggle aria-controls="navbarScroll" className={style.custom_toggler} /> */}
                                        <Button onClick={handleShow} className={style.canva_btn}>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <g clip-path="url(#clip0_4262_7253)">
                                                        <path d="M19.1667 9.16641H0.833333C0.373096 9.16641 0 9.53951 0 9.99975C0 10.46 0.373096 10.8331 0.833333 10.8331H19.1667C19.6269 10.8331 20 10.46 20 9.99975C20 9.53951 19.6269 9.16641 19.1667 9.16641Z" fill="#7743DC" />
                                                        <path d="M19.1667 3.33359H0.833333C0.373096 3.33359 0 3.70668 0 4.16692C0 4.62716 0.373096 5.00025 0.833333 5.00025H19.1667C19.6269 5.00025 20 4.62716 20 4.16692C20 3.70668 19.6269 3.33359 19.1667 3.33359Z" fill="#7743DC" />
                                                        <path d="M19.1667 15H0.833333C0.373096 15 0 15.3731 0 15.8333C0 16.2936 0.373096 16.6667 0.833333 16.6667H19.1667C19.6269 16.6667 20 16.2936 20 15.8333C20 15.3731 19.6269 15 19.1667 15Z" fill="#7743DC" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_4262_7253">
                                                            <rect width="20" height="20" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </Button>

                                        <Offcanvas show={show} onHide={handleClose} placement={'end'} >
                                            <Offcanvas.Header closeButton>

                                            </Offcanvas.Header>
                                            <Offcanvas.Body >
                                                {!AuthState.isLogin && <div className={style.navbarLink}>
                                                    <Nav.Link href={'/about-us'}>
                                                        {translate(
                                                            'LIABILITY_LAYOUT.HEADER.NORMAL_HEADER.ABOUT_US',
                                                        )}
                                                    </Nav.Link>
                                                    <Nav.Link href="#news-section">
                                                        {translate(
                                                            'LIABILITY_LAYOUT.HEADER.NORMAL_HEADER.NEWS',
                                                        )}
                                                    </Nav.Link>
                                                </div>
                                                }

                                                <Form
                                                    className={`d-flex  ${style.button} ${style.loginForms}`}
                                                >

                                                    <div className={style.custom_btns}>
                                                        <Button
                                                            className={`${style.loginBtn} ${style.hamLoginBtn}`}
                                                            onClick={() => {
                                                                // const isNavigationAllowed = false;
                                                                // handleSubmit(isNavigationAllowed);
                                                                setShowLoginModal(true);
                                                            }}
                                                        >
                                                            {translate(
                                                                'LIABILITY_LAYOUT.HEADER.NORMAL_HEADER.LOGIN',
                                                            )}
                                                        </Button>
                                                        <Button
                                                            className={style.startedBtn}
                                                            onClick={() => navigate('/sme-onboarding')}
                                                        >
                                                            {translate(
                                                                'LIABILITY_LAYOUT.HEADER.NORMAL_HEADER.GET_STARTED',
                                                            )}
                                                        </Button>
                                                    </div>
                                                    <Dropdown className={style.selectedLanguage}>
                                                        <Dropdown.Toggle
                                                            variant="light"
                                                            id="language-dropdown"
                                                        >
                                                            <p>
                                                                <Globe />{' '}
                                                                {
                                                                    languages.find(
                                                                        (l: any) =>
                                                                            l.name ===
                                                                            selectedLanguage,
                                                                    )?.display
                                                                }
                                                            </p>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {languages.map((language, index) => (
                                                                <Dropdown.Item
                                                                    className={style.dropdownItem}
                                                                    key={index}
                                                                    onClick={() =>
                                                                        handleLanguageSelect(
                                                                            language.name,
                                                                        )
                                                                    }
                                                                >
                                                                    {language.display}{' '}
                                                                    {selectedLanguage ===
                                                                        language.name && (
                                                                            <CheckCircleFill />
                                                                        )}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                </Form>

                                            </Offcanvas.Body>
                                        </Offcanvas>
                                    </div>
                                    <Navbar.Collapse id="navbarScroll" className={'d-none'}>
                                        <Navbar.Toggle
                                            aria-controls="navbarScroll"
                                            className='toggle-close-icon'>
                                            <img src={xcircle} alt="xcircle" className='close-icon' />
                                        </Navbar.Toggle>
                                        {!AuthState.isLogin && <Nav
                                            className={`me-auto my-2 my-lg-0 ${style.navbarLink}`}
                                            navbarScroll
                                        >
                                            <Nav.Link href={'/about-us'}>
                                                {translate(
                                                    'LIABILITY_LAYOUT.HEADER.NORMAL_HEADER.ABOUT_US',
                                                )}
                                            </Nav.Link>
                                            <Nav.Link href="#news-section">
                                                {translate(
                                                    'LIABILITY_LAYOUT.HEADER.NORMAL_HEADER.NEWS',
                                                )}
                                            </Nav.Link>
                                        </Nav>
                                        }
                                        <Form
                                            className={`d-flex gap-3 ${style.button} ${style.loginForms}`}
                                        >
                                            <Button
                                                className={`${style.loginBtn} ${style.hamLoginBtn}`}
                                                onClick={() => {
                                                    // const isNavigationAllowed = false;
                                                    // handleSubmit(isNavigationAllowed);
                                                    setShowLoginModal(true);
                                                }}
                                            >
                                                {translate(
                                                    'LIABILITY_LAYOUT.HEADER.NORMAL_HEADER.LOGIN',
                                                )}
                                            </Button>
                                            <Button
                                                className={style.startedBtn}
                                                onClick={() => navigate('/sme-onboarding')}
                                            >
                                                {translate(
                                                    'LIABILITY_LAYOUT.HEADER.NORMAL_HEADER.GET_STARTED',
                                                )}
                                            </Button>
                                            <Dropdown className={style.selectedLanguage}>
                                                <Dropdown.Toggle
                                                    variant="light"
                                                    id="language-dropdown"
                                                >
                                                    <p>
                                                        <Globe />{' '}
                                                        {
                                                            languages.find(
                                                                (l: any) =>
                                                                    l.name ===
                                                                    selectedLanguage,
                                                            )?.display
                                                        }
                                                    </p>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {languages.map((language, index) => (
                                                        <Dropdown.Item
                                                            className={style.dropdownItem}
                                                            key={index}
                                                            onClick={() =>
                                                                handleLanguageSelect(
                                                                    language.name,
                                                                )
                                                            }
                                                        >
                                                            {language.display}{' '}
                                                            {selectedLanguage ===
                                                                language.name && (
                                                                    <CheckCircleFill />
                                                                )}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form>
                                    </Navbar.Collapse></>
                                : <>
                                    <div
                                        className={`d-flex d-lg-none gap-3 ${style.button}`}
                                    >
                                        {/* <Navbar.Toggle aria-controls="navbarScroll" className={style.custom_toggler} /> */}
                                        <Button onClick={handleShow} className={style.canva_btn}>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <g clip-path="url(#clip0_4262_7253)">
                                                        <path d="M19.1667 9.16641H0.833333C0.373096 9.16641 0 9.53951 0 9.99975C0 10.46 0.373096 10.8331 0.833333 10.8331H19.1667C19.6269 10.8331 20 10.46 20 9.99975C20 9.53951 19.6269 9.16641 19.1667 9.16641Z" fill="#7743DC" />
                                                        <path d="M19.1667 3.33359H0.833333C0.373096 3.33359 0 3.70668 0 4.16692C0 4.62716 0.373096 5.00025 0.833333 5.00025H19.1667C19.6269 5.00025 20 4.62716 20 4.16692C20 3.70668 19.6269 3.33359 19.1667 3.33359Z" fill="#7743DC" />
                                                        <path d="M19.1667 15H0.833333C0.373096 15 0 15.3731 0 15.8333C0 16.2936 0.373096 16.6667 0.833333 16.6667H19.1667C19.6269 16.6667 20 16.2936 20 15.8333C20 15.3731 19.6269 15 19.1667 15Z" fill="#7743DC" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_4262_7253">
                                                            <rect width="20" height="20" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </Button>

                                        <Offcanvas show={show} onHide={handleClose} placement={'end'} >
                                            <Offcanvas.Header closeButton>

                                            </Offcanvas.Header>
                                            <Offcanvas.Body >
                                                {!AuthState.isLogin && <div className={style.navbarLink}>
                                                    <Nav.Link href={'/about-us'}>
                                                        {translate(
                                                            'LIABILITY_LAYOUT.HEADER.NORMAL_HEADER.ABOUT_US',
                                                        )}
                                                    </Nav.Link>
                                                    <Nav.Link href="#news-section">
                                                        {translate(
                                                            'LIABILITY_LAYOUT.HEADER.NORMAL_HEADER.NEWS',
                                                        )}
                                                    </Nav.Link>
                                                </div>
                                                }


                                                <Form
                                                    className={`d-flex  ${style.button} ${style.loginForms}`}
                                                >
                                                    <div className="db-user-dropdown d-sm-flex ">
                                                        <Dropdown>
                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                <span>
                                                                    <img
                                                                        // src={
                                                                        //     userProfile !== ''
                                                                        //         ? userProfile
                                                                        //         : profileIcon
                                                                        // }
                                                                        src={avtarImg}
                                                                        alt="user"
                                                                    />
                                                                </span>
                                                                {/* MOBILE  HERE */}
                                                                <div className={style.userDetails}>
                                                                    <h6>
                                                                        {user?.first_name} {user?.last_name}
                                                                    </h6>
                                                                    <p>
                                                                        {user?.email}
                                                                    </p>
                                                                </div>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item>
                                                                    <Link to="" onClick={() => handleNavigate("/user/edit-profile")}>
                                                                        <span>
                                                                            <img
                                                                                src={EditProfile}
                                                                                alt="edit profile"
                                                                            />
                                                                        </span>{' '}
                                                                        Edit Profile
                                                                    </Link>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item>
                                                                    <Link to="" onClick={() => handleNavigate("/user/change-password")}>
                                                                        <span>
                                                                            <img
                                                                                src={ChangePassword}
                                                                                alt="change password"
                                                                            />
                                                                        </span>{' '}
                                                                        Change Password
                                                                    </Link>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item>
                                                                    <Link to="" onClick={() => handleNavigate("/user/edit-company-profile")}>
                                                                        <span>
                                                                            <img
                                                                                src={EditCompanyProfile}
                                                                                alt="edit company profile"
                                                                            />
                                                                        </span>{' '}
                                                                        Edit Company Profile
                                                                    </Link>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item>
                                                                    <Link to="" onClick={() => handleNavigate("/user/edit-team-setting")}>
                                                                        <span>
                                                                            <img
                                                                                src={EditTeamSettings}
                                                                                alt="edit team setting"
                                                                            />
                                                                        </span>{' '}
                                                                        Edit Team Settings
                                                                    </Link>
                                                                </Dropdown.Item>

                                                                <Dropdown.Item>
                                                                    <Link to="" onClick={() => handleNavigate("/user/edit-team-setting")}>
                                                                        <span>
                                                                            <img
                                                                                src={Language}
                                                                                alt="Language"
                                                                            />
                                                                        </span>{' '}
                                                                        English
                                                                    </Link>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item>
                                                                    <Link
                                                                        to=""
                                                                        onClick={() => {
                                                                            Cookies.remove('userToken', { path: '', domain: '.covrzy.com' })
                                                                            Cookies.remove('userToken', { domain: '.covrzy.com' })
                                                                            dispatch(LogoutAction())
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            <img src={LogoutIcon} alt="logout" />
                                                                        </span>{' '}
                                                                        Logout
                                                                    </Link>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    <div className={style.custom_btns}>
                                                        <Button
                                                            className={`${style.loginBtn} ${style.hamLoginBtn} ${style.logoutBtn} ${style.hamLogoutBtn}`}
                                                            onClick={handleUserLogout}
                                                        >
                                                            {translate(
                                                                'LIABILITY_LAYOUT.HEADER.LOGGED_IN_HEADER.LOGOUT',
                                                            )}
                                                        </Button>
                                                    </div>
                                                    <Dropdown className={style.selectedLanguage}>
                                                        <Dropdown.Toggle
                                                            variant="light"
                                                            id="language-dropdown"
                                                        >
                                                            <p>
                                                                <Globe />{' '}
                                                                {
                                                                    languages.find(
                                                                        (l: any) =>
                                                                            l.name ===
                                                                            selectedLanguage,
                                                                    )?.display
                                                                }
                                                            </p>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {languages.map((language, index) => (
                                                                <Dropdown.Item
                                                                    className={style.dropdownItem}
                                                                    key={index}
                                                                    onClick={() =>
                                                                        handleLanguageSelect(
                                                                            language.name,
                                                                        )
                                                                    }
                                                                >
                                                                    {language.display}{' '}
                                                                    {selectedLanguage ===
                                                                        language.name && (
                                                                            <CheckCircleFill />
                                                                        )}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                </Form>

                                            </Offcanvas.Body>
                                        </Offcanvas>
                                    </div>
                                    <Navbar.Collapse id="navbarScroll" className={'d-none'}>
                                        <Navbar.Toggle
                                            aria-controls="navbarScroll"
                                            className='toggle-close-icon'>
                                            <img src={xcircle} alt="xcircle" className='close-icon' />
                                        </Navbar.Toggle>
                                        {!AuthState.isLogin && <Nav
                                            className={`me-auto my-2 my-lg-0 ${style.navbarLink}`}
                                            navbarScroll
                                        >
                                            <Nav.Link href={'/about-us'}>
                                                {translate(
                                                    'LIABILITY_LAYOUT.HEADER.NORMAL_HEADER.ABOUT_US',
                                                )}
                                            </Nav.Link>
                                            <Nav.Link href="#news-section">
                                                {translate(
                                                    'LIABILITY_LAYOUT.HEADER.NORMAL_HEADER.NEWS',
                                                )}
                                            </Nav.Link>
                                        </Nav>
                                        }
                                        <Form
                                            className={`d-flex gap-3 ${style.button} ${style.loginForms}`}
                                        >
                                            <div className="db-user-dropdown d-flex justify-content-center">
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <span>
                                                            <img
                                                                // src={
                                                                //     userProfile !== ''
                                                                //         ? userProfile
                                                                //         : profileIcon
                                                                // }
                                                                src={avtarImg}
                                                                alt="user"
                                                            />
                                                        </span>
                                                        {/* DESKTOP HERE */}
                                                        <div className={style.userDetails}>
                                                            <h6>
                                                                {user?.first_name} {user?.last_name}
                                                            </h6>
                                                            <p>
                                                                {user?.email}
                                                            </p>
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item>
                                                            <Link to="" onClick={() => handleNavigate("/user/edit-profile")}>
                                                                <span>
                                                                    <img
                                                                        src={EditProfile}
                                                                        alt="edit profile"
                                                                    />
                                                                </span>{' '}
                                                                Edit Profile
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item>
                                                            <Link to="" onClick={() => handleNavigate("/user/change-password")}>
                                                                <span>
                                                                    <img
                                                                        src={ChangePassword}
                                                                        alt="change password"
                                                                    />
                                                                </span>{' '}
                                                                Change Password
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item>
                                                            <Link to="" onClick={() => handleNavigate("/user/edit-company-profile")}>
                                                                <span>
                                                                    <img
                                                                        src={EditCompanyProfile}
                                                                        alt="edit company profile"
                                                                    />
                                                                </span>{' '}
                                                                Edit Company Profile
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item>
                                                            <Link to="" onClick={() => handleNavigate("/user/edit-team-setting")}>
                                                                <span>
                                                                    <img
                                                                        src={EditTeamSettings}
                                                                        alt="edit team setting"
                                                                    />
                                                                </span>{' '}
                                                                Edit Team Settings
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item>
                                                            <Link to="" onClick={() => handleNavigate("/user/edit-team-setting")}>
                                                                <span>
                                                                    <img
                                                                        src={Language}
                                                                        alt="Language"
                                                                    />
                                                                </span>{' '}
                                                                English
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item>
                                                            <Link
                                                                to=""
                                                                onClick={() => {
                                                                    Cookies.remove('userToken', { path: '', domain: '.covrzy.com' })
                                                                    Cookies.remove('userToken', { domain: '.covrzy.com' })
                                                                    dispatch(LogoutAction())
                                                                }}
                                                            >
                                                                <span>
                                                                    <img src={LogoutIcon} alt="logout" />
                                                                </span>{' '}
                                                                Logout
                                                            </Link>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            {/* <Button
                                                className={`${style.loginBtn} ${style.hamLoginBtn} ${style.logoutBtn} ${style.hamLogoutBtn}`}
                                                onClick={handleUserLogout}
                                            >
                                                {translate(
                                                    'LIABILITY_LAYOUT.HEADER.LOGGED_IN_HEADER.LOGOUT',
                                                )}
                                            </Button>
                                            <Button
                                                className={style.startedBtn}
                                                onClick={() => navigate('/sme-onboarding')}
                                            >
                                                {translate(
                                                    'LIABILITY_LAYOUT.HEADER.NORMAL_HEADER.GET_STARTED',
                                                )}
                                            </Button> */}
                                            <Dropdown className={style.selectedLanguage}>
                                                <Dropdown.Toggle
                                                    variant="light"
                                                    id="language-dropdown"
                                                >
                                                    <p>
                                                        <Globe />{' '}
                                                        {
                                                            languages.find(
                                                                (l: any) =>
                                                                    l.name ===
                                                                    selectedLanguage,
                                                            )?.display
                                                        }
                                                    </p>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {languages.map((language, index) => (
                                                        <Dropdown.Item
                                                            className={style.dropdownItem}
                                                            key={index}
                                                            onClick={() =>
                                                                handleLanguageSelect(
                                                                    language.name,
                                                                )
                                                            }
                                                        >
                                                            {language.display}{' '}
                                                            {selectedLanguage ===
                                                                language.name && (
                                                                    <CheckCircleFill />
                                                                )}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form>
                                    </Navbar.Collapse></>
                        }
                    </Container>
                </Navbar>
            </div>

            <CustomLoginModal showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} navigationAllowed={false} />
            <TalkToExpertModal showTalkToExpertModal={showModal && !show} handleTalkToExpertModalClose={handleTalkToExpertModalClose} />
        </>
    );
};

export default QuoteHeader;
