import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './styles.module.scss';
import IconStroke from '../../assets/images/Icon-troke.png';
import Form from 'react-bootstrap/Form';
import * as yup from 'yup';
import { phonesRegx } from '../../Helper/commonFunction';
import { PHONE } from '../../constants/main';
import { useFormik } from 'formik';
import CustomLabel from '../../common/CustomLabel';
import CustomPhoneInput from '../../common/PhoneInput';
import moment from 'moment';
import axios from '../../axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { TOAST_SHOW } from '../../Redux/Actions/ToastAction';
import { IReduxState } from '../../utils/types';
import { DecryptData } from '../../common/CryptoJSToken';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { Clock, XLg } from 'react-bootstrap-icons';

interface RequestCallbackFormModalProps {
    showRequestCallbackFormModal: boolean;
    setShowRequestCallbackFormModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

const RequestCallbackFormModal: React.FC<RequestCallbackFormModalProps> = ({
    showRequestCallbackFormModal,
    setShowRequestCallbackFormModal,
}) => {
    const AuthState = useSelector((state: IReduxState) => state.Auth);

    const userData = DecryptData(AuthState.user);

    const [countryCode, setCountryCode] = useState<string>('91');
    const [submittingResponse, setSubmittingResponse] = useState(false);

    const dispatch = useDispatch();

    const callbackValidationSchema = yup.object().shape({
        firstName: yup.string().required('Please enter your first name.'),
        lastName: yup.string().required('Please enter your last name.'),
        phone: yup
            .string()
            .required('Please enter your phone number.')
            .matches(phonesRegx[countryCode], PHONE),
        date: yup.string().required('Please enter your preferred date.'),
        time: yup.string().required('Please enter your preferred time.'),
    });

    const handleFormSubmission = async (values: any) => {
        setSubmittingResponse(true);

        const { firstName, lastName, phone, date, time } = values;

        const payload = {
            name: firstName + ' ' + lastName,
            phone: phone,
            date: date,
            time: time,
        };

        await axios
            .post('v1/callback-requests', payload)
            .then(() => {
                dispatch({
                    type: TOAST_SHOW,
                    payload: {
                        message: 'Request registered successfully.',
                        severity: 'success',
                        show: true,
                    },
                });
                setSubmittingResponse(false);
                handleCallbackClose();
            })
            .catch(() => {
                dispatch({
                    type: TOAST_SHOW,
                    payload: {
                        message: 'Something went wrong. Please try again.',
                        severity: 'danger',
                        show: true,
                    },
                });
                setSubmittingResponse(false);
            });
    };

    const callbackFormik = useFormik({
        initialValues: {
            firstName: userData?.first_name ?? '',
            lastName: userData?.last_name ?? '',
            phone: userData?.phone_number ?? '',
            date: '',
            time: '',
        },
        validationSchema: callbackValidationSchema,
        onSubmit: handleFormSubmission,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        callbackFormik?.setFieldValue(name, value);
    };

    const handleCallbackClose = () => {
        callbackFormik?.resetForm();
        setShowRequestCallbackFormModal(false);
    };

    const clockFormat = 'h:mm a';

    const CustomFormControl = React.forwardRef(
        ({ children }: any, ref: any) => {
            function onCustomFormControlChange(value: any) {
                if (value && value.format(clockFormat)) {
                    callbackFormik?.setFieldValue(
                        'time',
                        value.format(clockFormat),
                    );
                } else {
                    callbackFormik?.setFieldValue('time', '');
                }
            }

            return (
                <>
                    <TimePicker
                        name="time"
                        showSecond={false}
                        onChange={onCustomFormControlChange}
                        format={clockFormat}
                        use12Hours
                        value={
                            (callbackFormik?.values?.time &&
                                moment(
                                    callbackFormik?.values?.time,
                                    clockFormat,
                                )) ||
                            undefined
                        }
                        className={styles.timePicker}
                        popupClassName={styles.timePickerPopUp}
                        placeholder="hh:mm AM"
                        inputIcon={
                            (!callbackFormik?.values?.time && <Clock />) ||
                            undefined
                        }
                        clearIcon={
                            (callbackFormik?.values?.time && <XLg />) ||
                            undefined
                        }
                    />
                </>
            );
        },
    );

    return (
        <Modal
            show={showRequestCallbackFormModal}
            onHide={handleCallbackClose}
            centered
            className={styles.formPopop}
        >
            <div className={styles.formModel}>
                <div>
                    <div className={styles.formBox}>
                        <p>Thank you for choosing Covrzy!</p>
                        <h2>We will get back to you at your preferred time</h2>
                    </div>
                    <Form onSubmit={callbackFormik?.handleSubmit}>
                        <div className={styles.formlabel}>
                            <div className={styles.formGroup}>
                                <input
                                    id="date"
                                    className="form-control"
                                    name="date"
                                    type="date"
                                    placeholder=""
                                    onChange={handleChange}
                                    value={callbackFormik?.values?.date}
                                    min={moment()
                                        .add(1, 'days')
                                        .format('YYYY-MM-DD')}
                                />
                                <label>
                                    Preferred Date
                                    <span className="gl-form-asterisk"></span>
                                </label>
                                {callbackFormik?.errors?.date &&
                                    callbackFormik?.touched?.date && (
                                        <p className="text-danger">
                                            {callbackFormik?.errors?.date}
                                        </p>
                                    )}
                            </div>
                            <div className={styles.formGroup}>
                                <Form.Control
                                    type="time"
                                    name="time"
                                    value={callbackFormik?.values?.time}
                                    onChange={(e: any) => {
                                        callbackFormik?.setFieldValue(
                                            'time',
                                            e,
                                        );
                                    }}
                                    as={CustomFormControl}
                                    className="form-control"
                                />

                                <label>
                                    Preferred Time
                                    <span className="gl-form-asterisk"></span>
                                </label>
                                {callbackFormik?.errors?.time &&
                                    callbackFormik?.touched?.time && (
                                        <p className="text-danger">
                                            {callbackFormik?.errors?.time}
                                        </p>
                                    )}
                            </div>
                            {/* <div className={styles.formGroup}>
                                <input
                                    id="time"
                                    className="form-control"
                                    type="time"
                                    name="time"
                                    placeholder=""
                                    onChange={handleChange}
                                    value={callbackFormik?.values?.time}
                                />
                                <label>
                                    Preferred Time
                                    <span className="gl-form-asterisk"></span>
                                </label>
                                {callbackFormik?.errors?.time &&
                                    callbackFormik?.touched?.time && (
                                        <p className="text-danger">
                                            {callbackFormik?.errors?.time}
                                        </p>
                                    )}
                            </div> */}
                        </div>
                        <div className={styles.formGroup}>
                            <Form.Group
                                className={styles.formgroupBox}
                                controlId="exampleForm.ControlInput1"
                            >
                                <div>
                                    <Form.Control
                                        type="text"
                                        placeholder="First Name"
                                        name="firstName"
                                        value={
                                            callbackFormik?.values?.firstName
                                        }
                                        onChange={handleChange}
                                    />
                                    {callbackFormik?.errors?.firstName &&
                                        callbackFormik?.touched?.firstName && (
                                            <p className="text-danger">
                                                {callbackFormik?.errors?.firstName?.toString()}
                                            </p>
                                        )}
                                </div>
                                <div>
                                    <Form.Control
                                        type="text"
                                        placeholder="Last Name"
                                        name="lastName"
                                        value={callbackFormik?.values?.lastName}
                                        onChange={handleChange}
                                    />
                                    {callbackFormik?.errors?.lastName &&
                                        callbackFormik?.touched?.lastName && (
                                            <p className="text-danger">
                                                {callbackFormik?.errors?.lastName?.toString()}
                                            </p>
                                        )}
                                </div>
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group
                                className={styles.formgroupBox}
                                controlId="phone"
                            >
                                <div className={styles.lastFrom}>
                                    <Form.Group className="form-group">
                                        <CustomLabel label="Phone Number *" />
                                        <CustomPhoneInput
                                            country={'in'}
                                            placeholder=""
                                            value={
                                                callbackFormik?.values?.phone
                                            }
                                            onChange={(
                                                phone: any,
                                                country: any,
                                            ) => {
                                                setCountryCode(
                                                    country?.dialCode,
                                                );
                                                callbackFormik?.setFieldValue(
                                                    'phone',
                                                    phone,
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                    {callbackFormik?.errors?.phone &&
                                        callbackFormik?.touched?.phone && (
                                            <p className="text-danger">
                                                {callbackFormik?.errors?.phone?.toString()}
                                            </p>
                                        )}
                                </div>
                            </Form.Group>
                        </div>
                        <div className={styles.formbottomBtn}>
                            <button>Continue</button>
                        </div>
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

export default RequestCallbackFormModal;
