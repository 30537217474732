import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Collapse, Modal, Row } from 'react-bootstrap';
import ICICLogo from '../../../assets/images/iciciLombard.svg';
import InsurerLogoComponent from '../../../components/InsurerLogo/index';
import SecurityCardComponent from '../../../common/SecurityCardComponent';
import PolicySummaryCard from '../../../components/PolicySummary';
import { IReduxState } from '../../../utils/types';
import { formatAmount } from '../../../common/FormatAmount';
import { dataMap } from '../../../utils/QuoteData/ProductsDetails';
import style from './style.module.scss';
import ArrowBack from '../../../assets/images/arrow-left.svg';
import MobileArrowBack from '../../../assets/images/mobile-left.svg';
import { isObjectEmpty } from '../../../Helper/commonFunction';
import { UpdateUserSessionAction } from '../../../Redux/Actions/SessionAction';
import { useNavigate } from 'react-router-dom';
import EstimateSummaryCard from '../../../components/EstimateSummary';
import TerritoryComponent from '../../../components/TerritoryComponent';
import { RecommendationApiIntegrationAction } from '../../../Redux/Actions/RecommendationAction';
import PolicyCardComponent from '../../../components/PolicyCardv2';
import CustomButton from '../../../common/Buttons/CustomButton';
import Form from 'react-bootstrap/Form';
import { ChevronDown, PencilSquare } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import greencheck from '../../../assets/images/greenCheck.svg';
import { TOAST_SHOW } from '../../../Redux/Actions/ToastAction';
import {
    AddressDetail,
    ContactDetail,
    IndexManagement,
    InsuredDetail,
    PersonalDetail,
    PolicyDetail,
} from '..';
import { SaveInitiatePurchasePolicyAction } from '../../../Redux/Actions/PolicyInputAction';
import { formatDateStringToddMMyyyy } from '../../../utils/DateUtil';

export function getCoverData(
    coverName: string,
    coverSI: string,
    premium: string,
) {
    const coverData = dataMap[coverName];
    if (coverData) {
        coverData.coverSI = coverSI;
        coverData.coverName = coverName;
        coverData.premium = premium ? Number(premium)?.toFixed(2) : premium;
    } else {
        console.error(`Data not found for coverName: ${coverName}`);
    }
    return coverData;
}

export function formatDateString(dateString: string) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}

interface AdditionalDetailsComponentProps
    extends IndexManagement,
    PolicyDetail {
    personalDetail: PersonalDetail;
    setPersonalDetail: (detail: PersonalDetail) => void;
    contactDetail: ContactDetail;
    setContactDetail: (detail: ContactDetail) => void;
    addressDetail: AddressDetail;
    setAddressDetail: (detail: AddressDetail) => void;
    insuredDetail: InsuredDetail;
    setInsuredDetail: (detail: InsuredDetail) => void;
}

function AdditionalDetailsComponent2(props: AdditionalDetailsComponentProps) {
    const {
        index,
        setIndex,
        policyStartDate,
        setPolicyStartDate,
        policyEndDate,
        setPolicyEndDate,
        isLitigation,
        setIsLitigation,
        litigationDate,
        setLitigationDate,
        personalDetail,
        setPersonalDetail,
        contactDetail,
        setContactDetail,
        addressDetail,
        setAddressDetail,
        insuredDetail,
        setInsuredDetail,
    } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t: translate } = useTranslation();

    const [uniqueCoverElements, setUniqueCoverElements] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [childHeight, setChildHeight] = useState('auto');

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmChecked, setConfirmChecked] = useState(false);
    const handleShowConfirmationModal = () => {
        setShowConfirmationModal(true);
    };

    const handleCloseConfirmationModalCancel = () => {
        setShowConfirmationModal(false);
        setConfirmChecked(false);
    };

    const handleCloseConfirmationModal = () => {
        if (confirmChecked) {
            setShowConfirmationModal(false);
            setConfirmChecked(false);

            dispatch(
                SaveInitiatePurchasePolicyAction({
                    body: {
                        addressDetail,
                        personalDetail,
                        customerType: '', // ???
                        insuredDetail,
                        contactDetail,
                    },
                    navigate,
                }),
            );

            props.setIndex(props.index + 1);
        } else {
            dispatch({
                type: TOAST_SHOW,
                payload: {
                    message: 'Please check the checkbox',
                    severity: 'danger',
                    show: true,
                },
            });
        }
    };

    const { aboutBusiness } = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );

    const CustomerInformation = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );

    const [openTab, setOpenTab] = useState('BasicDetails');

    const { quoteResponse } = useSelector(
        (state: IReduxState) => state.LiabilityQuote,
    );

    const { recommendationResponse } =
        useSelector((state: IReduxState) => state.Recommendations);

    const userSession = useSelector((state: IReduxState) => state.UserSession);

    const { policies } = CustomerInformation;

    // useEffect(() => {
    //     const startDate = formatDateString(policyStartDate);
    //     setStartDate(startDate);
    //     setEndDate(policyEndDate);
    // }, [policyEndDate, policyStartDate]);

    // useEffect(() => {
    //     if (!isObjectEmpty(aboutBusiness)) {
    //         dispatch(RecommendationApiIntegrationAction(aboutBusiness));
    //     }
    // }, [aboutBusiness]);

    // useEffect(() => {
    //     if (userSession.userSessionData.paymentResponse) {
    //         console.log('console.log');
    //     }
    // }, []);

    useEffect(() => {
        if (endDate && startDate) {
            dispatch(
                UpdateUserSessionAction({
                    id: userSession.id, // always add for params
                    nextSessionState: 'quote_completed',
                    correlationId: quoteResponse.correlationId,
                    userSessionData: {
                        quoteResponse: quoteResponse,
                        startDate: startDate,
                        endDate: endDate,
                    },
                }),
            );
        }
    }, [endDate, startDate]);

    useEffect(() => {
        try {
            const objectToConsider =
                props.index === 4 ? recommendationResponse : quoteResponse;
            if (!isObjectEmpty(objectToConsider)) {
                const allCovers = objectToConsider.risks.flatMap(
                    (risk: any) => risk.covers,
                );

                const quoteCards = allCovers.map((el: any) => {
                    return getCoverData(el.coverName, el.coverSI, el.premium);
                });

                setUniqueCoverElements(quoteCards);
                // } else {
                //     const recommendCover = recommendationResponse.risks.flatMap(
                //         (risks: any) => risks.covers,
                //     );
                //     const recommendCards = recommendCover.map((el: any) => {
                //         return getCoverData(el.coverName, el.coverSI, el.premium);
                //     });
                //     setUniqueCoverElements(recommendCards);
            }
        } catch (error) { }
    }, [policies, quoteResponse, recommendationResponse]);

    console.log('uniqueCoverElements', uniqueCoverElements);

    useEffect(() => {
        const tallestChildHeight = Math.max(
            ...Array.from(document.querySelectorAll('.dynamic-height')).map(
                (child) => child.clientHeight,
            ),
        );

        setChildHeight(tallestChildHeight + 'px');
    }, [childHeight]);

    return (
        <>
            <div className={style.main}>
                <div className={style.header}>
                    <h6>Review Policy Details</h6>
                    <Button className={style.custom_nextButton} onClick={() => {
                        props.setIndex(props.index - 1);
                    }}>
                        <PencilSquare />
                        <span>Edit</span>
                    </Button>
                </div>

                <div className={style.plan_section}>
                    <div className={style.plan_heading}>
                        <h6>Coverage Plans</h6>
                    </div>

                    <div className={style.product_tab_section}>
                        <div className={style.product_tab}>
                            <div>
                                <img src={greencheck} alt="" />
                            </div>
                            <h6>Cyber Liability</h6>
                        </div>

                        <div className={style.product_tab}>
                            <div>
                                <img src={greencheck} alt="" />
                            </div>
                            <h6>Directors and Officers Liability Insurance</h6>
                        </div>

                        <div className={style.product_tab}>
                            <div>
                                <img src={greencheck} alt="" />
                            </div>
                            <h6>CGL Product Liability Insurance</h6>
                        </div>
                    </div>
                </div>

                <Row>
                    <Col lg={12} className="mb-3">
                        <Card className={style.detailsCard}>
                            <Card.Header
                                onClick={() =>
                                    setOpenTab((prevState) =>
                                        prevState === 'BasicDetails'
                                            ? ''
                                            : 'BasicDetails',
                                    )
                                }
                            >
                                <Card.Title>
                                    {' '}
                                    Policy Details
                                </Card.Title>
                                <div className={style.purchaseCollapse}>
                                    <Button className={style.downArrow}>
                                        <ChevronDown />
                                    </Button>
                                </div>
                            </Card.Header>
                            <Collapse in={openTab === 'BasicDetails'}>
                                <Card.Body>
                                    <ul className={style.detailsList}>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Policy Start Date
                                            </p>
                                            <span>
                                                {formatDateStringToddMMyyyy(policyStartDate)}
                                            </span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Policy End Date
                                            </p>
                                            <span>{formatDateStringToddMMyyyy(policyEndDate)}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Prior and Pending Litigation Date
                                            </p>
                                            <span>{formatDateStringToddMMyyyy(litigationDate)}</span>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Collapse>
                        </Card>
                    </Col>
                    <Col lg={12} className="mb-3">
                        <Card className={style.detailsCard}>
                            <Card.Header
                                onClick={() =>
                                    setOpenTab((prevState) =>
                                        prevState === 'InsuredDetails'
                                            ? ''
                                            : 'InsuredDetails',
                                    )
                                }
                            >
                                <Card.Title>
                                    {' '}
                                    Insured Details
                                </Card.Title>
                                <div className={style.purchaseCollapse}>
                                    <Button className={style.downArrow}>
                                        <ChevronDown />
                                    </Button>
                                </div>
                            </Card.Header>
                            <Collapse in={openTab === 'InsuredDetails'}>
                                <Card.Body>
                                    <ul className={style.detailsList}>
                                        <li className={style.detailsItems}>
                                            <p>
                                                First Name
                                            </p>
                                            <span>
                                                {insuredDetail.insuredFirstName}
                                            </span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Last Name
                                            </p>
                                            <span>{insuredDetail.insuredLastName}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Email ID
                                            </p>
                                            <span>{contactDetail.email}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Mobile Number
                                            </p>
                                            <span>{contactDetail.phone}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                DoB
                                            </p>
                                            <span>{formatDateStringToddMMyyyy(insuredDetail.insuredDOB)}</span>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Collapse>
                        </Card>
                    </Col>
                    <Col lg={12} className="mb-3">
                        <Card className={style.detailsCard}>
                            <Card.Header
                                onClick={() =>
                                    setOpenTab((prevState) =>
                                        prevState === 'UploadDocuments'
                                            ? ''
                                            : 'UploadDocuments',
                                    )
                                }
                            >
                                <Card.Title>
                                    {' '}
                                    Upload Documents
                                </Card.Title>
                                <div className={style.purchaseCollapse}>
                                    <Button className={style.downArrow}>
                                        <ChevronDown />
                                    </Button>
                                </div>
                            </Card.Header>
                            <Collapse in={openTab === 'UploadDocuments'}>
                                <Card.Body>
                                    <ul className={style.detailsList}>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Policy Start Date
                                            </p>
                                            <span>
                                                dd/mm/yyyy
                                            </span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Policy End Date
                                            </p>
                                            <span>dd/mm/yyyy</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Prior and Pending Litigation Date
                                            </p>
                                            <span>dd/mm/yyyy</span>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Collapse>
                        </Card>
                    </Col>
                    <Col lg={12} className="mb-3">
                        <Card className={style.detailsCard}>
                            <Card.Header
                                onClick={() =>
                                    setOpenTab((prevState) =>
                                        prevState === 'SubsidiaryDetails'
                                            ? ''
                                            : 'SubsidiaryDetails',
                                    )
                                }
                            >
                                <Card.Title>
                                    {' '}
                                    Subsidiary Details
                                </Card.Title>
                                <div className={style.purchaseCollapse}>
                                    <Button className={style.downArrow}>
                                        <ChevronDown />
                                    </Button>
                                </div>
                            </Card.Header>
                            <Collapse in={openTab === 'SubsidiaryDetails'}>
                                <Card.Body>
                                    <ul className={style.detailsList}>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Policy Start Date
                                            </p>
                                            <span>
                                                dd/mm/yyyy
                                            </span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Policy End Date
                                            </p>
                                            <span>dd/mm/yyyy</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Prior and Pending Litigation Date
                                            </p>
                                            <span>dd/mm/yyyy</span>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>

                <div className={style.plan_section2}>
                    <div className={style.plan_heading}>
                        <h6>Coverage Plans</h6>
                    </div>
                    <div className={style.product_tab_section}>
                        <div className={style.product_tab}>
                            <div>
                                <img src={greencheck} alt="" />
                            </div>
                            <h6>Cyber Liability</h6>
                        </div>

                        <div className={style.product_tab}>
                            <div>
                                <img src={greencheck} alt="" />
                            </div>
                            <h6>Directors and Officers
                                Liability Insurance</h6>
                        </div>

                        <div className={style.product_tab}>
                            <div>
                                <img src={greencheck} alt="" />
                            </div>
                            <h6>CGL Product Liability
                                Insurance</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`footer-buttons ${style.custom_footerButtons}`}>
                <div className={style.backButton}>
                    {props.index !== 0 && (
                        <div
                            className="footer-back-button"
                            onClick={() => {
                                props.setIndex(props.index - 1);
                            }}
                        >
                            <img
                                src={ArrowBack}
                                alt=""
                                style={{ cursor: 'pointer' }}
                                className={style.desktop_arrow}
                            />
                            <img
                                src={MobileArrowBack}
                                alt=""
                                style={{ cursor: 'pointer' }}
                                className={style.mobile_arrow}
                            />
                        </div>
                    )}{' '}
                </div>
                <CustomButton
                    className={`next-button ${style.custom_nextButton}`}
                    type="Submit"
                    buttonTitle={
                        <div className={style.arrow_svg}>
                            Next
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="12"
                                        transform="rotate(-180 12 12)"
                                        fill="white"
                                    />
                                    <g clip-path="url(#clip0_1627_17530)">
                                        <path
                                            d="M13.6302 12.0004L9.91766 8.28794L10.9782 7.22744L15.7512 12.0004L10.9782 16.7734L9.91766 15.7129L13.6302 12.0004Z"
                                            fill="#774AD9"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1627_17530">
                                            <rect
                                                width="18"
                                                height="18"
                                                fill="white"
                                                transform="matrix(-1 0 0 -1 21 21)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </div>
                    }
                    onClick={handleShowConfirmationModal}
                />
            </div>
            <Modal
                show={showConfirmationModal}
                onHide={handleCloseConfirmationModalCancel}
                centered
                className={style.custom_modal_content}
            >
                <Modal.Body className={style.custom_modal_body}>
                    <div className={style.details_heading}>
                        <h5>Policy Details</h5>
                    </div>
                    <div className={style.details_body}>
                        <p>Are you sure you want to submit the details</p>
                        <div className={style.details_checkbox}>
                            <Form.Check
                                aria-label="option 1"
                                checked={confirmChecked}
                                onChange={() =>
                                    setConfirmChecked(!confirmChecked)
                                }
                            />
                            <p>
                                I declare that all submitted information is true
                                and accurate.
                            </p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className={style.details_footer}>
                    <Button
                        className={style.cancel_btn}
                        onClick={handleCloseConfirmationModalCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={style.submit_btn}
                        onClick={handleCloseConfirmationModal}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AdditionalDetailsComponent2;
