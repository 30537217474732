import React, { useState, useEffect } from 'react';
import { Form, Dropdown, DropdownButton, Offcanvas } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import { Link } from 'react-router-dom';
import style from './style.module.scss';
import vector from '../../assets/images/checkfill-o-whitebg.svg';
import DropDown from '../../assets/images/drop-down.svg';
import dropDownClose from '../../assets/images/drop-down-close.svg';
import checkmark from '../../assets/images/Checkmark-O-bg.svg';
import plus from '../../assets/images/plus-icon.svg';
import tickMark from '../../assets/images/tick-mark.svg';
import vectorPurple from '../../assets/images/check-mark-whiteBg.svg';
import dropDownP from '../../assets/images/drop-down(P).svg';
import closeIconPurple from '../../assets/images/close-icon-p.svg';
import CheckFill from '../../assets/images/CheckFill-PurpleBg.svg';
import { GAActions, GACategories } from '../../utils/googleAnalytics/gaData';
import useGAEvent from '../../utils/googleAnalytics/useGAEvent';
import { NumberFormat } from '../../common/NumberFormat';
import { coverSummary } from '../../utils/StaticDatas';
import { formatAmount } from '../../common/FormatAmount/index';
import UpwardArrowPurple from '../../assets/images/UpwardArrowPurple.svg';
import UpwardArrow from '../../assets/images/UpwardArrow.svg';
import { ArrowRightCircleFill, Check, CheckLg } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import actsOfTerrorism from "../../assets/images/actsOfTerrorism.svg";
import burstingPipe from "../../assets/images/burstingPipe.svg";
import fireAndExplosion from "../../assets/images/fireAndExplosion.svg";
import lightning from "../../assets/images/lightning.svg";
import naturalDisasters from "../../assets/images/naturalDisasters.svg";
import theftCaused from "../../assets/images/theftCaused.svg";
import tickMarks from "../../assets/images/tickMark.svg";

import ThirdParty from "../../assets/images/ThirdPartyLiability.svg";
import FirstParty from "../../assets/images/FirstPartyLiability.svg";
import Services from "../../assets/images/Services.svg";
import Extensions from "../../assets/images/Extensions.svg";

interface PolicyCardComponentProps {
    toggleCoverage: (id: number) => void;
    isSelected: boolean;
    data: any;
    recommended: boolean;
    showCoverSI: boolean;
    UI: boolean;
    height: string;
    setCoverSI: any;
    coverSI: any;
    popular?: boolean;
    disabledCoverSI?: boolean;
}

function PolicyCardComponent(props: PolicyCardComponentProps) {
    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [offCanvasType, setOffCanvasType] = useState(-1);
    const [selectedValue, setSelectedValue] = useState<number>(
        formatAmount(props.data.coverSI),
    );

    const { t: translate } = useTranslation();

    const { sendGAEvent } = useGAEvent(GACategories.InsurancePackagesPage);

    const handleAddCoverageClick = () => {
        props.toggleCoverage(props.data);
        handleSelect(props.data.coverSI);
    };

    useEffect(() => { }, [props.isSelected]);

    const handleToggleReadMore = () => {
        setShowMore(!showMore);
    };

    const matchingCoverSI = (coverName: string) => {
        const findObj = coverSummary.find((element: any) => {
            return element.coverName === coverName;
        });
        return findObj;
    };

    const handleSelect = (eventKeyIndex: any) => {

        props.setCoverSI((cover: any) => {
            const newCoverSI = new Map(cover);
            newCoverSI.set(props.data.name, +eventKeyIndex);
            return newCoverSI;
        });
        setSelectedValue(formatAmount(+eventKeyIndex));
    };

    const handleOpenOffCanvas = (type: number) => {
        setOffCanvasType(type);
    }

    const handleCloseOffCanvas = () => {
        setOffCanvasType(-1);
    }

    useEffect(() => {
        setSelectedValue(formatAmount(typeof props.coverSI === 'number' ? props.coverSI : props.coverSI?.get(props.data.name) ?? props.data.coverSI));
    }, [props.coverSI])

    if (!props.data) return <></>;

    return (
        <div className={`${style.main} ${props.recommended ? style.recommendImage : ""}`} id={props.data.id}>
            <div className={style.header}>
                <div className={style.headerContent}>
                    <div className={style.custom_header}>
                        <h5>Sum Insured</h5>
                        <div className={style.policy_dropdown}>
                            {/* <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" className={style.policy_dropdown_btn}>
                                    <span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18" fill="none">
                                        <path d="M2.03259 0.367188H12.6857L11.6204 2.24793H8.1475C8.65885 2.79334 9.04236 3.4328 9.26608 4.12867H12.6857L11.6204 6.00941H9.4898C9.35741 7.1817 8.78038 8.28017 7.85225 9.12675C6.92411 9.97334 5.69928 10.5184 4.37628 10.6736V10.7113H3.63056L10.0225 17.2939H7.35917L0.967278 10.7113V8.83052H3.63056C5.50552 8.83052 7.06088 7.60804 7.31655 6.00941H0.967278L2.03259 4.12867H6.99696C6.40038 3.01903 5.122 2.24793 3.63056 2.24793H0.967278L2.03259 0.367188Z" fill="#263238" />
                                    </svg></span>
                                    <p>5 Crore</p>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {matchingCoverSI(props.data.name)?.coverSI.map(
                                        (el: number, index: number) => {
                                            return (
                                                <Dropdown.Item
                                                    eventKey={el}
                                                    active={selectedValue === el}
                                                    key={index}
                                                >
                                                    {formatAmount(el)}
                                                </Dropdown.Item>
                                            );
                                        },
                                    )}
                                </Dropdown.Menu>
                            </Dropdown> */}
                            <DropdownButton className={style.policy_dropdown_btn}
                                title={selectedValue}
                                onSelect={handleSelect}
                                variant="#ffff"
                                disabled={props?.disabledCoverSI}
                            >
                                {matchingCoverSI(props.data.name)?.coverSI.map(
                                    (el: number, index: number) => {
                                        return (
                                            <Dropdown.Item
                                                eventKey={el}
                                                active={selectedValue === el}
                                                key={index}
                                            >
                                                {formatAmount(el)}
                                            </Dropdown.Item>
                                        );
                                    },
                                )}
                            </DropdownButton>
                        </div>
                    </div>

                    <div className={style.card_content}>
                        <div className={style.card_heading}>
                            <h4>{props.data?.name}</h4>
                            {
                                props.data?.desc?.length < 70 && <p>{props.data?.desc}</p>
                            }
                            {
                                props.data?.desc?.length > 70 && (showMore ? <p>{props.data?.desc}</p> : <div>
                                    <p>
                                        {props.data?.desc?.substring(0, 70)}...<span onClick={handleToggleReadMore} style={{
                                            cursor: 'pointer'
                                        }}>See More</span>
                                    </p>
                                </div>)
                            }
                        </div>
                    </div>
                </div>
                <div className={style.card_box_sec}>
                    <div className={style.card_box_content1} onClick={() => handleOpenOffCanvas(0)}>
                        <div className={style.card_box_head}>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                                    <circle cx="16" cy="16.0156" r="16" fill="#774AD9" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.2001 11.4915L15.5834 20.8141L9.6001 15.9326L10.729 14.6065L15.3294 18.3587L21.8242 10.4141L23.2001 11.4915Z" fill="white" />
                                </svg>
                            </span>
                            <h5>Coverages</h5>
                        </div>
                        <div className={style.card_box_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <rect y="0.222656" width="16.3636" height="16" rx="8" fill="#F5F2FC" />
                                <g clip-path="url(#clip0_1627_5786)">
                                    <path d="M9.15965 8.22208L6.98465 10.3971L7.60595 11.0184L10.4023 8.22208L7.60595 5.42578L6.98465 6.04708L9.15965 8.22208Z" fill="#774AD9" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1627_5786">
                                        <rect width="10.5455" height="10.5455" fill="white" transform="translate(2.90909 2.94922)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>

                    <div className={style.card_box_content2} onClick={() => handleOpenOffCanvas(1)}>
                        <div className={style.card_box_head}>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                                    <circle cx="16" cy="16.0156" r="16" fill="#774AD9" />
                                    <path d="M23.5339 15.0165H8.92571C7.69143 14.9683 7.69143 16.8455 8.92571 16.7973H23.5081C24.72 16.8444 24.7435 15.0165 23.5328 15.0165H23.5339Z" fill="white" />
                                </svg>
                            </span>
                            <h5>Deductibles</h5>
                        </div>
                        <div className={style.card_box_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <rect y="0.222656" width="16.3636" height="16" rx="8" fill="#F5F2FC" />
                                <g clip-path="url(#clip0_1627_5786)">
                                    <path d="M9.15965 8.22208L6.98465 10.3971L7.60595 11.0184L10.4023 8.22208L7.60595 5.42578L6.98465 6.04708L9.15965 8.22208Z" fill="#774AD9" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1627_5786">
                                        <rect width="10.5455" height="10.5455" fill="white" transform="translate(2.90909 2.94922)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>

                </div>

                <div className={style.plan_section}>
                    <div className={props?.isSelected ? style.plan_head_selected : style.plan_head} onClick={handleAddCoverageClick}>
                        <h5>starting at</h5>
                        <div className={style.plan_txt}>
                            <span>{NumberFormat(props?.data?.premium)} / year</span>
                        </div>
                    </div>
                    <div className={style.plan_small_txt}>
                        <p>Listed price is under ideal conditions and is subject to change on the basis of the specific details of your business.</p>
                    </div>
                </div>
            </div>
            <div>
                <Offcanvas show={offCanvasType !== -1} onHide={handleCloseOffCanvas} placement="end">
                    <Offcanvas.Header closeButton></Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className={style.canvas_header}>
                            <h5>{props.data?.riskName}</h5>
                            <p>{props.data?.desc}</p>
                        </div>

                        <div className={style.accordion_section}>
                            {
                                offCanvasType === 0 ? <>
                                    <h4>What’s Covered?</h4>
                                    <div>
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className={style.accordion_header}>
                                                    <div className={style.accordion_content}>
                                                        <div className={style.accordion_info}>
                                                            <img src={fireAndExplosion} alt="" />
                                                            <h5>Fire & Explosion</h5>
                                                        </div>
                                                        <div className={style.accordion_small_txt}>
                                                            <h6>Covered</h6>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body >
                                                    <div className={style.custom_accordion_body}>
                                                        <p>Offers protection from financial damages resulting from specified natural calamities like earthquakes, volcanic eruptions, storms, cyclones, tempest etc</p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header className={style.accordion_header}>
                                                    <div className={style.accordion_content}>
                                                        <div className={style.accordion_info}>
                                                            <img src={lightning} alt="" />
                                                            <h5>Lightning</h5>
                                                        </div>
                                                        <div className={style.accordion_small_txt}>
                                                            <h6>Covered</h6>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body >
                                                    <div className={style.custom_accordion_body}>
                                                        <p>Offers protection from financial damages resulting from specified natural calamities like earthquakes, volcanic eruptions, storms, cyclones, tempest etc</p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header className={style.accordion_header}>
                                                    <div className={style.accordion_content}>
                                                        <div className={style.accordion_info}>
                                                            <img src={actsOfTerrorism} alt="" />
                                                            <h5>Acts of Terrorism</h5>
                                                        </div>
                                                        <div className={style.accordion_small_txt}>
                                                            <h6>Covered</h6>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body >
                                                    <div className={style.custom_accordion_body}>
                                                        <p>Offers protection from financial damages resulting from specified natural calamities like earthquakes, volcanic eruptions, storms, cyclones, tempest etc</p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header className={style.accordion_header}>
                                                    <div className={style.accordion_content}>
                                                        <div className={style.accordion_info}>
                                                            <img src={burstingPipe} alt="" />
                                                            <h5>Bursting/overflowing of water tanks & pipes</h5>
                                                        </div>
                                                        <div className={style.accordion_small_txt}>
                                                            <h6>Covered</h6>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body >
                                                    <div className={style.custom_accordion_body}>
                                                        <p>Offers protection from financial damages resulting from specified natural calamities like earthquakes, volcanic eruptions, storms, cyclones, tempest etc</p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>


                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header className={style.accordion_header}>
                                                    <div className={style.accordion_content}>
                                                        <div className={style.accordion_info}>
                                                            <img src={naturalDisasters} alt="" />
                                                            <h5>Natural Disasters</h5>
                                                        </div>
                                                        <div className={style.accordion_small_txt}>
                                                            <h6>Covered</h6>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body >
                                                    <div className={style.custom_accordion_body}>
                                                        <p>Offers protection from financial damages resulting from specified natural calamities like earthquakes, volcanic eruptions, storms, cyclones, tempest etc</p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>


                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header className={style.accordion_header}>
                                                    <div className={style.accordion_content}>
                                                        <div className={style.accordion_info}>
                                                            <img src={theftCaused} alt="" />
                                                            <h5>Theft caused only by any above events</h5>
                                                        </div>
                                                        <div className={style.accordion_small_txt}>
                                                            <h6>Covered</h6>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body >
                                                    <div className={style.custom_accordion_body}>
                                                        <p>Offers protection from financial damages resulting from specified natural calamities like earthquakes, volcanic eruptions, storms, cyclones, tempest etc</p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>


                                            <Accordion.Item eventKey="6">
                                                <Accordion.Header className={style.accordion_header}>
                                                    <div className={style.accordion_content}>
                                                        <div className={style.accordion_info}>
                                                            <img src={tickMarks} alt="" />
                                                            <h5>Theft caused only by any above events</h5>
                                                        </div>
                                                        <div className={style.accordion_small_txt}>
                                                            <h6>Covered</h6>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body >
                                                    <div className={style.custom_accordion_body}>
                                                        <p>Offers protection from financial damages resulting from specified natural calamities like earthquakes, volcanic eruptions, storms, cyclones, tempest etc</p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="7">
                                                <Accordion.Header className={style.accordion_header}>
                                                    <div className={style.accordion_content}>
                                                        <div className={style.accordion_info}>
                                                            <img src={naturalDisasters} alt="" />
                                                            <h5>Natural Disasters</h5>
                                                        </div>
                                                        <div className={style.accordion_small_txt}>
                                                            <h6>Covered</h6>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body >
                                                    <div className={style.custom_accordion_body}>
                                                        <p>Offers protection from financial damages resulting from specified natural calamities like earthquakes, volcanic eruptions, storms, cyclones, tempest etc</p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>


                                            <Accordion.Item eventKey="8">
                                                <Accordion.Header className={style.accordion_header}>
                                                    <div className={style.accordion_content}>
                                                        <div className={style.accordion_info}>
                                                            <img src={theftCaused} alt="" />
                                                            <h5>Theft caused only by any above events</h5>
                                                        </div>
                                                        <div className={style.accordion_small_txt}>
                                                            <h6>Covered</h6>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body >
                                                    <div className={style.custom_accordion_body}>
                                                        <p>Offers protection from financial damages resulting from specified natural calamities like earthquakes, volcanic eruptions, storms, cyclones, tempest etc</p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>


                                        </Accordion>
                                    </div>

                                    <div className={style.accordion_section2}>
                                        <h5>What’s Not Covered?</h5>

                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className={style.accordion_header}>
                                                    <div className={style.accordion_content}>
                                                        <div className={style.accordion_info}>
                                                            <img src={fireAndExplosion} alt="" />
                                                            <h5>Fire & Explosion</h5>
                                                        </div>
                                                        <div className={style.accordion_small_txt2}>
                                                            <h6>Excluded</h6>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body >
                                                    <div className={style.custom_accordion_body}>
                                                        <p>Offers protection from financial damages resulting from specified natural calamities like earthquakes, volcanic eruptions, storms, cyclones, tempest etc</p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>

                                    </div></>
                                    :


                                    <div className={style.accordion_section3}>
                                        <h5>Deductibles</h5>

                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className={style.accordion_header}>
                                                    <div className={style.accordion_content}>
                                                        <div className={style.accordion_info}>
                                                            <img src={ThirdParty} alt="" />
                                                            <h5>Third Party Liability</h5>
                                                        </div>
                                                        <div className={style.accordion_small_txt2}>
                                                            <h6>Deductible</h6>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body >
                                                    <div className={style.custom_accordion_body}>
                                                        <p>Offers protection from financial damages resulting from specified natural calamities like earthquakes, volcanic eruptions, storms, cyclones, tempest etc</p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>


                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header className={style.accordion_header}>
                                                    <div className={style.accordion_content}>
                                                        <div className={style.accordion_info}>
                                                            <img src={FirstParty} alt="" />
                                                            <h5>First Party Liability</h5>
                                                        </div>
                                                        <div className={style.accordion_small_txt2}>
                                                            <h6>Deductible</h6>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body >
                                                    <div className={style.custom_accordion_body}>
                                                        <p>Offers protection from financial damages resulting from specified natural calamities like earthquakes, volcanic eruptions, storms, cyclones, tempest etc</p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>


                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header className={style.accordion_header}>
                                                    <div className={style.accordion_content}>
                                                        <div className={style.accordion_info}>
                                                            <img src={Services} alt="" />
                                                            <h5>Services</h5>
                                                        </div>
                                                        <div className={style.accordion_small_txt2}>
                                                            <h6>Deductible</h6>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body >
                                                    <div className={style.custom_accordion_body}>
                                                        <p>Offers protection from financial damages resulting from specified natural calamities like earthquakes, volcanic eruptions, storms, cyclones, tempest etc</p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header className={style.accordion_header}>
                                                    <div className={style.accordion_content}>
                                                        <div className={style.accordion_info}>
                                                            <img src={Extensions} alt="" />
                                                            <h5>Extensions</h5>
                                                        </div>
                                                        <div className={style.accordion_small_txt2}>
                                                            <h6>Deductible</h6>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body >
                                                    <div className={style.custom_accordion_body}>
                                                        <p>Offers protection from financial damages resulting from specified natural calamities like earthquakes, volcanic eruptions, storms, cyclones, tempest etc</p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>






                                        </Accordion>

                                    </div>
                            }
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </div>
    );
}

export default PolicyCardComponent;
