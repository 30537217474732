import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreatePaymentOrderAction } from '../../Redux/Actions/PaymentActions';
import { IReduxState } from '../../utils/types';
import FullPageSpinner from '../../common/FullPageSpinner';
import OrangeButton from '../../common/Buttons/OrangeButton';
import callIcon from '../../assets/images/call-icon.svg';
import { NumberFormat } from '../../common/NumberFormat';
import style from './style.module.scss';
import { getCoverData } from '../../pages/V2Quote/Checkout';
import { isObjectEmpty } from '../../Helper/commonFunction';
import CallbackModal from '../RequestCallbackl';
import ProgressBar from '../ProgressScore/ProgressBar';
import RequestCallbackFormModal from '../../common/RequestCallbackFormModal';
import { data } from '../../utils/QuoteData/ProductsDetails';

interface IEstimateProps {
    index?: number;
    setIndex?: any;
    selectedAddOns?: any[]; // Added prop to handle selected add-ons
}

interface CoverageObj {
    premium: any;
}

function EstimateSummaryCard({
    index,
    setIndex,
    selectedAddOns = [],
}: IEstimateProps) {
    const [total, setTotal] = useState(0);
    const [gst, setGst] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [cartProducts, setCartProducts] = useState<CoverageObj[]>([]);
    const [open, setOpen] = useState<boolean>(false);

    const coversData = data[0].cards?.map((data) => ({
        name: data.name?.trim(),
        risk: data.risks[0].riskName,
        coverName: data.risks[0].coverName,
    }));

    const dispatch = useDispatch();

    const Recommendations = useSelector(
        (state: IReduxState) => state.Recommendations,
    );

    const Payment = useSelector((state: IReduxState) => state.Payment);
    const { quoteResponse } = useSelector(
        (state: IReduxState) => state.LiabilityQuote,
    );

    // const { recommendationResponse } = Recommendations;
    const { orderCreatedResponse, req_loading } = Payment;

    const { callback_success } = useSelector(
        (state: IReduxState) => state.Support,
    );

    const handleModalOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (cartProducts && cartProducts?.length !== 0) {
            const sum = cartProducts.reduce(function (total, coverageObj) {
                return total + parseFloat(coverageObj.premium);
            }, 0);

            const tax = sum * 0.18;
            setTotal(sum);
            setGst(tax);
            setGrandTotal(sum + tax);
        } else {
            if (quoteResponse) {
                setTotal(
                    Number((quoteResponse?.basicPremium ?? 0)?.toFixed(2)),
                );
                setGst(Number((quoteResponse?.taxOnPremium ?? 0).toFixed(2)));
                setGrandTotal(
                    Number((quoteResponse?.grossPremium ?? 0).toFixed(2)),
                );
            }
        }
    }, [cartProducts, quoteResponse]);

    useEffect(() => {
        if (!isObjectEmpty(quoteResponse)) {
            const quote = quoteResponse.risks.flatMap(
                (risk: any) => risk.covers,
            );

            const quoteCards = quote.map((el: any) => {
                return getCoverData(el.coverName, el.coverSI, el.premium);
            });

            setCartProducts(quoteCards);
        }
    }, [quoteResponse]);

    useEffect(() => {
        if (callback_success) {
            setOpen(false);
        }
    }, [callback_success]);

    const paymentHandler = () => {
        dispatch(
            CreatePaymentOrderAction({
                amount: total,
                tax: gst,
                correlationId: quoteResponse?.correlationId,
                description: `Initiate Payment of ${grandTotal}`,
                insurer: 'iciciLombard',
            }),
        );
    };

    useEffect(() => {
        if (Object.keys(orderCreatedResponse).length !== 0) {
            const paymentURL = `${orderCreatedResponse.domainURL}/pay/${orderCreatedResponse.accessToken}`;
            window.location.href = paymentURL;
        }
    }, [orderCreatedResponse]);

    return (
        <section>
            <section className={`${style.main} ${style.desktop_drawer}`}>
                {/* <div className={style.heroHeader}>
                <h5>Estimate summary</h5>
            </div> */}

                <div>
                    <ProgressBar
                        score={
                            index === 3
                                ? 60
                                : index == 4
                                ? 80
                                : index === 5
                                ? 100
                                : 0
                        }
                    />
                </div>

                <div className={style.director_container}>
                    {quoteResponse?.risks?.length === 1 ? (
                        <div className={style.director_content}>
                            <h5>
                                {coversData?.find(
                                    (record) =>
                                        record?.risk ===
                                            quoteResponse?.risks?.[0]
                                                ?.riskName &&
                                        record?.coverName ===
                                            quoteResponse?.risks?.[0]
                                                ?.covers?.[0]?.coverName,
                                )?.name ?? quoteResponse?.risks?.[0]?.riskName}
                            </h5>
                        </div>
                    ) : (
                        <>
                            <div className={style.container}>
                                {quoteResponse?.risks?.map(
                                    (el: any, index: number) => (
                                        <div
                                            className={style.productDetails}
                                            key={index}
                                        >
                                            <h4>
                                                {coversData?.find(
                                                    (record) =>
                                                        record?.risk ===
                                                            el.riskName &&
                                                        record?.coverName ===
                                                            el.covers?.[0]
                                                                ?.coverName,
                                                )?.name ?? el.riskName}
                                            </h4>
                                            <h6>
                                                {NumberFormat(
                                                    el?.covers?.[0]?.premium,
                                                )}
                                            </h6>
                                        </div>
                                    ),
                                )}
                            </div>
                        </>
                    )}
                    {index && index >= 5 && (
                        <div className={style.priceContent}>
                            <div className={style.total}>
                                <h5>Total</h5>
                                <h6>{NumberFormat(total)}</h6>
                            </div>
                            <div className={style.total2}>
                                <h5>GST @ 18%</h5>
                                <h6>{NumberFormat(gst)}</h6>
                            </div>
                        </div>
                    )}
                    <div className={style.grandTotal}>
                        <h6>Grand Total</h6>
                        <p>
                            <span>starting at </span>
                            <span
                                className={`${style.price} ${
                                    index && index < 5 ? style.price_blur : ''
                                }`}
                            >
                                {NumberFormat(grandTotal)}
                            </span>
                            <span>/ year</span>
                        </p>
                    </div>
                    <OrangeButton
                        className={style.custom_orenge_btn}
                        buttonName={
                            index === 5 ? 'Proceed To Pay' : 'See exact price'
                        }
                        onClick={() => {
                            if (index === 5) {
                                paymentHandler(); // Proceed to payment
                            } else {
                                index && setIndex && setIndex(5); // Go to next step if not on index 5
                            }
                        }}
                    />
                    <button
                        className={style.talkToMe}
                        onClick={handleModalOpen}
                    >
                        Talk to an expert <img src={callIcon} alt="" />
                    </button>
                </div>

                <div className={style.terms}>
                    <a href="">Quotation Terms & Conditions</a>
                </div>
                {req_loading && <FullPageSpinner />}
                {req_loading && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            background: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 9999,
                        }}
                    />
                )}
                {open && (
                    <RequestCallbackFormModal
                        showRequestCallbackFormModal={open}
                        setShowRequestCallbackFormModal={setOpen}
                    />
                )}
            </section>

            <div className={style.mobile_drawer}>
                <div className={style.mobile_drawer_inner}>
                    <ProgressBar
                        score={
                            index === 3
                                ? 60
                                : index == 4
                                ? 80
                                : index === 5
                                ? 100
                                : 0
                        }
                    />
                </div>
                <div className={style.total_amount_box}>
                    <h5>Total Amount</h5>
                    <div
                        className={`${style.start_amount_rate} ${index && index < 5 ? style.price_blur : ''}`}
                    >
                        <p>starting at</p>
                        <span>₹ 5,75,000</span>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default EstimateSummaryCard;
