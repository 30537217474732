import React, { useState, useEffect } from 'react';
import TypeFilterLayout from '../../components/TypeFilterLayout';
import style from './style.module.scss';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { data } from '../../utils/QuoteData/ProductsDetails';
import { useDispatch, useSelector } from 'react-redux';
import {
    ResetCustomerInformationAction,
    SaveSelectedProductsAction,
} from '../../Redux/Actions/CustomerInformationAction';
import { ClearRecommendationData } from '../../Redux/Actions/RecommendationAction';
import { GAActions, GACategories } from '../../utils/googleAnalytics/gaData';
import useGAEvent from '../../utils/googleAnalytics/useGAEvent';
import GetRecommendationSection from '../../components/GetRecommendationSection';
import arrow from '../../assets/images/next-arrow-p.svg';
import PolicyCardComponent from '../../components/PolicyCard';
import { TOAST_SHOW } from '../../Redux/Actions/ToastAction';
import Footer from '../../common/QuoteLayout/Footer/index';
import { ClearSelectedProducts } from '../../Redux/Actions/SaveProductAction';
import { ClearQuoteDataAction } from '../../Redux/Actions/QuoteAction';
import { IReduxState } from '../../utils/types';
// import useMSG90OTPWidget from '../../common/QuoteLayout/MSG90widgetfunction';
import {
    ClearUserSession,
    GetUserSessionByIdAction,
    GetUserSessions,
    SaveUserStateAction,
} from '../../Redux/Actions/SessionAction';
import { isObjectEmpty } from '../../Helper/commonFunction';
import ResumeModalComponent from '../../components/ResumeModal';
import CustomLoginModal from '../../components/CustomLoginModal';
import LiabilityHero from '../../components/LiabilityHero';
import LiabilityCovrzyDetails from '../../components/LiabilityCovrzyDetails';
import Slider from 'react-slick';

export enum UserSessionFilter {
    All = 'all',
    Active = 'active',
    Completed = 'completed',
    PaymentCompleted = 'paymentCompleted',
}

function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return <div className={className} onClick={onClick} />;
}

function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return <div className={className} onClick={onClick} />;
}

function InsurancePackages() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { sendGAEvent } = useGAEvent(GACategories.InsurancePackagesPage);
    const [productItems, setProductItems] = useState<any[]>([]);
    const [selectedPackage, setSelectedPackage] = useState<string | null>(
        'Startup',
    );
    const [childHeight, setChildHeight] = useState('auto');
    const [card, setCard] = useState<any[]>([]);
    const [secondaryCard, setSecondaryCard] = useState<any[]>([]);
    const [resume, setResume] = useState<boolean>(false);

    const { isLogin } = useSelector((state: IReduxState) => state.Auth);
    const userSession = useSelector((state: IReduxState) => state.UserSession);

    // const { handleSubmit } = useMSG90OTPWidget();
    const [showLoginModal, setShowLoginModal] = useState(false)

    useEffect(() => {
        const tallestChildHeight = Math.max(
            ...Array.from(document.querySelectorAll('.dynamic-height')).map(
                (child) => child.clientHeight,
            ),
        );
        setChildHeight(tallestChildHeight + 'px');
    }, [childHeight]);

    useEffect(() => {
        dispatch(ResetCustomerInformationAction());
        dispatch(ClearRecommendationData());
        dispatch(ClearSelectedProducts());
        dispatch(ClearQuoteDataAction());
        localStorage.removeItem('order');
    }, []);

    useEffect(() => {
        const filteredData = data.filter(
            (el) => el.category === selectedPackage,
        );
        if (filteredData.length > 0 && filteredData[0].cards) {
            setCard(filteredData[0].cards);

            let allProducts = data.map((item) => item.cards).flat();

            const policyIdMap: any = {};

            allProducts.forEach((item: any) => {
                policyIdMap[item.policyId] = item;
            });
        }
    }, [selectedPackage]);

    // TODO: @AnoonaCovrzy Need to fix something
    // useEffect(() => {
    //     if (isLogin) {
    //         dispatch(GetUserSessions(UserSessionFilter.Active));
    //     } else {
    //         setResume(false);
    //     }
    // }, [isLogin]);

    // useEffect(() => {
    //     if (isObjectEmpty(userSession) && !isLogin) {
    //         setResume(false);
    //         return;
    //     }
    //     setResume(true);
    // }, [userSession]);

    const addProductHandleClick = (productDetails: any) => {
        sendGAEvent(GAActions.AddCoverageClicked);

        const identifier = productDetails.id;

        if (productItems.some((element: any) => element.id === identifier)) {
            setProductItems(
                productItems.filter((el: any) => el.id !== identifier),
            );
        } else {
            setProductItems([...productItems, productDetails]);
        }
    };

    const handlePackageSelect = (packageName: string) => {
        sendGAEvent(GAActions.PolicyFilterClicked);
        if (!(selectedPackage === packageName)) {
            setSelectedPackage(packageName);
        }
    };

    function checkIfProductExists(id: number) {
        return productItems.some((obj) => obj.id === id);
    }

    const handleDispatchButton = (productDetails: any) => {
        sendGAEvent(GAActions.AddCoverageClicked);

        const identifier = productDetails.id;

        if (productItems.some((element: any) => element.id === identifier)) {
            setProductItems(
                productItems.filter((el: any) => el.id !== identifier),
            );
        } else {
            setProductItems([...productItems, productDetails]);
        }

        sendGAEvent(GAActions.StartApplicationClicked);

        dispatch(
            SaveSelectedProductsAction({
                policies: productItems,
                businessCategory: selectedPackage,
            }),
        );

        if (isLogin) {
            if (productItems.length !== 0) {
                dispatch(
                    SaveUserStateAction({
                        userSessionData: {
                            selectedProductsData: {
                                policies: productItems,
                                businessCategory: selectedPackage,
                            },
                        },
                    }),
                );
                dispatch(ClearUserSession());
                navigate('/quote/info');
            } else {
                dispatch({
                    type: TOAST_SHOW,
                    payload: {
                        message: 'Please select atleast one product.',
                        severity: 'danger',
                        show: true,
                    },
                });
            }
        } else {
            setShowLoginModal(true);
        }
    };

    const updateProductItems = (newItems: any) => {
        setProductItems(newItems);
    };
    const handleContinueButtonClick = () => {
        dispatch(GetUserSessionByIdAction({ id: userSession.id }));
        navigate('/quote/info');
    };
    const handleCloseModal = () => {
        dispatch(ClearUserSession());
        setResume(false);
    };

    const testimonials = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        initialSlide : 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        gap: 15,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide : 1,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    initialSlide : 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    initialSlide : 1,
                },
            },
        ],
    };

    useEffect(() => {
        if (data) {
            const otherCategory = data?.find((item: any) => item?.category === "Others")?.cards
            if (otherCategory) {
                setSecondaryCard(otherCategory)
            }
        }
    }, [data])


    return (
        <>
            <div className={style.main}>
                <ResumeModalComponent
                    open={resume}
                    onClose={handleCloseModal}
                    resumeOnclick={handleContinueButtonClick}
                />
                {/* <div className={style.heroHeader}>
                    <h5 className="noselect">
                        Explore <span>Insurance</span> Options for your Business
                    </h5>
                    <p className="noselect">
                        Discover tailored insurance options for your business.
                        Explore, filter, and request quotes effortlessly.
                    </p>
                </div> */}
                <LiabilityHero />
                <Container className={style.herocontainer}>
                    <div className={style.body}>
                        <div className={style.title}>
                            <h5>Select your Industry to View our Recommendations</h5>

                            <div className={style.filters}>
                                <TypeFilterLayout
                                    onClick={handlePackageSelect}
                                    selectedPackage={selectedPackage}
                                    productLength={card.length}
                                    updateProductItems={updateProductItems}
                                />
                            </div>
                        </div>
                        {/* <div className={style.container}>
                        <div className={style.cardSec}> */}
                        <Slider {...testimonials}>
                            {card.map((element: any, index: number) => (
                                <div
                                    key={index}
                                    className={`${style.card} dynamic-height`} style={{ height: "100%" }}
                                >
                                    <PolicyCardComponent
                                        toggleCoverage={
                                            addProductHandleClick
                                        }
                                        data={element}
                                        isSelected={checkIfProductExists(
                                            element.id,
                                        )}
                                        recommended={false}
                                        showCoverSI={false}
                                        UI={false}
                                        height={childHeight}
                                        setCoverSI={false}
                                        coverSI={false}
                                    />
                                </div>
                            ))}
                        </Slider>
                        {/* </div>
                    </div> */}
                        {/* <div className={style.footer}>
                        <button
                            // style={customStyle}
                            onClick={handleDispatchButton}
                        >
                            Next <img src={arrow} alt="" />
                        </button>
                    </div> */}
                        <div>
                            <div className={style.package_title}>
                                <h5>You can also check out</h5>
                            </div>
                        </div>
                        {/* <div className={style.container}>
                        <div className={style.cardSec}> */}
                        <Slider {...testimonials}>
                            {secondaryCard.map((element: any, index: number) => (
                                <div key={index}
                                    className={`${style.card} dynamic-height`} style={{ height: "100%" }}
                                >
                                    <PolicyCardComponent
                                        toggleCoverage={
                                            handleDispatchButton
                                        }
                                        data={element}
                                        isSelected={checkIfProductExists(
                                            element.id,
                                        )}
                                        recommended={false}
                                        showCoverSI={false}
                                        UI={false}
                                        height={childHeight}
                                        setCoverSI={false}
                                        coverSI={false}
                                        popular={index === 0 ? true : false}
                                    />
                                </div>
                            ))}
                        </Slider>
                        {/* </div>
                    </div> */}
                        {/* <div className={style.footer}>
                        <button
                            // style={customStyle}
                            onClick={handleDispatchButton}
                        >
                            Next <img src={arrow} alt="" />
                        </button>
                    </div> */}
                    </div>
                </Container>

                <div>
                    {/* <GetRecommendationSection /> */}
                    <LiabilityCovrzyDetails showFeaturedInSection={true} showWhyUsSection={true} showJoinBusinessSection={true} showTestimonialSection={true} showCollaborationSection={true} />
                </div>
                <div>
                    <Footer />
                </div>
            </div>
            <CustomLoginModal showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} navigationAllowed={false} />
        </>
    );
}

export default InsurancePackages;
