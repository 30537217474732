import React from 'react';
import style from './style.module.scss';
import { Row, Container, Col } from 'react-bootstrap';
import brandlogo from '../../../assets/images/logo.png';
import visa from '../../../assets/images/Visa.png';
import amex from '../../../assets/images/Amex.png';
import mastercard from '../../../assets/images/Mastercard.png';
import googlePay from '../../../assets/images/GooglePay.png';
import frame99 from '../../../assets/images/Frame12.png';
import google from '../../../assets/images/google.png';
import instagram from '../../../assets/images/instagram.png';
import linkdin from '../../../assets/images/linkdin.png';
import xlogo from '../../../assets/images/xlogo.png';
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const navigate = useNavigate();

    const { t: translate } = useTranslation();

    return (
        <div className={style.footerSection}>
            <Container className={style.herocontainer}>
                <div className={style.footerBox}>
                    <div className={style.brandLogo}>
                        <img
                            src={brandlogo}
                            alt="brandlogo"
                            style={{ objectFit: 'contain' }}
                            onClick={() => navigate('/')}
                        />
                    </div>
                    <div
                        className={`${style.footerLink} ${style.footerResponsive}`}
                    >
                        <h2>{translate('LIABILITY_LAYOUT.FOOTER.QUICK_LINKS')}</h2>
                        <ul>
                            <li>
                                <a href="/about-us">{translate('LIABILITY_LAYOUT.FOOTER.ABOUT_US')}</a>
                            </li>
                            <li>
                                <a href="#faqs-section">{translate('LIABILITY_LAYOUT.FOOTER.FAQS')}</a>
                            </li>
                            {/* <li>
                                <a href="#">{translate('LIABILITY_LAYOUT.FOOTER.RESOURCES')}</a>
                            </li> */}
                        </ul>
                    </div>
                    <div className={style.footerAccoding}>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header
                                    className={style.accordionHeader}
                                >
                                    {translate('LIABILITY_LAYOUT.FOOTER.QUICK_LINKS')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul className={style.linkItem}>
                                        <li>
                                            <a href="/about-us">{translate('LIABILITY_LAYOUT.FOOTER.ABOUT_US')}</a>
                                        </li>
                                        <li>
                                            <a href="#faqs-section">{translate('LIABILITY_LAYOUT.FOOTER.FAQS')}</a>
                                        </li>
                                        {/* <li>
                                            <a href="#">{translate('LIABILITY_LAYOUT.FOOTER.RESOURCES')}</a>
                                        </li> */}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>{translate('LIABILITY_LAYOUT.FOOTER.PRODUCTS')}</Accordion.Header>
                                <Accordion.Body>
                                    <ul className={style.linkItem}>
                                        <li>
                                            <a href="/stock-insurance">
                                                {translate('LIABILITY_LAYOUT.FOOTER.STOCK_INSURANCE')}
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/fire-insurance">
                                                {translate('LIABILITY_LAYOUT.FOOTER.FIRE_AND_THEFT_INSURANCE')}
                                            </a>
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div
                        className={`${style.footerLink} ${style.footerResponsive}`}
                    >
                        <h2>{translate('LIABILITY_LAYOUT.FOOTER.PRODUCTS')}</h2>
                        <ul>
                            <li>
                                <a href="/stock-insurance">{translate('LIABILITY_LAYOUT.FOOTER.STOCK_INSURANCE')}</a>
                            </li>
                            <li>
                                <a href="/fire-insurance">
                                    {translate('LIABILITY_LAYOUT.FOOTER.FIRE_AND_THEFT_INSURANCE')}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={style.footerLink}>
                        <h2>{translate('LIABILITY_LAYOUT.FOOTER.CONTACT_US')}</h2>
                        <ul>
                            <li>
                                <a href="#">
                                    {translate('LIABILITY_LAYOUT.FOOTER.SUPPORT_QUERY_CONTENT')}
                                    <span>support@covrzy.com</span>
                                </a>
                            </li>
                            <li>
                                <a href="#">{translate('LIABILITY_LAYOUT.FOOTER.SUPPORT_QUERY_TIME')}</a>
                            </li>
                            <li>
                                <a href="#">
                                    {translate('LIABILITY_LAYOUT.FOOTER.SUPPORT_TALK_TO_US')}
                                    <span> +91 93549 63947</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={style.footerLink}>
                        <h2>{translate('LIABILITY_LAYOUT.FOOTER.MEET_US')}</h2>
                        <p>
                            {translate('LIABILITY_LAYOUT.FOOTER.MEET_ADDRESS')}
                        </p>
                        <div className={style.footerIcon}>
                            <ul className={style.iconList}>
                                <a
                                    href="https://in.linkedin.com/company/covrzy"
                                    target="_blank"
                                >
                                    <li>
                                        <img src={linkdin} alt="linkedin" />
                                    </li>
                                </a>
                                <a
                                    href="https://www.google.com/search?q=covrzy&rlz=1C1ONGR_enIN1101IN1103&oq=covrzy&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyBggBEEUYPDIGCAIQRRg8MgYIAxBFGDwyEAgEEC4YrwEYxwEYgAQYjgUyBwgFEAAYgAQyBggGEEUYPDIGCAcQRRg8qAIAsAIA&sourceid=chrome&ie=UTF-8"
                                    target="_blank"
                                >
                                    <li>
                                        <img src={google} alt="google" />
                                    </li>
                                </a>
                                <a
                                    href="https://www.instagram.com/covrzy/"
                                    target="_blank"
                                >
                                    <li>
                                        <img src={instagram} alt="instagram" />
                                    </li>
                                </a>
                                <a
                                    href="https://x.com/Covrzy_In"
                                    target="_blank"
                                >
                                    <li>
                                        <img src={xlogo} alt="xlogo" />
                                    </li>
                                </a>
                            </ul>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={style.payment_sec2}>
                        <div className={style.footerBottom}>
                            <div className={style.footerImage}>
                                <h5>{translate('LIABILITY_LAYOUT.FOOTER.PAYMENT_METHODS_TITLE')}</h5>
                                <div className={style.footerimageBox}>
                                    <img src={visa} alt="visa" />
                                    {/* <img src={amex} alt="amex" /> */}
                                    <img src={mastercard} alt="Mastercard" />
                                    {/* <img src={googlePay} alt="googlePay" /> */}
                                </div>

                            </div>
                            <div className={style.securedSectopn}>
                                <h5>{translate('LIABILITY_LAYOUT.FOOTER.PAYMENT_METHODS_SECURED_WITH')}</h5>
                                <img src={frame99} alt="pci dss" />
                            </div>
                        </div>
                    </div>

                    <div className={style.copyrightSection2}>
                        <div className={style.copyrightBox}>
                            <p>
                                {translate('LIABILITY_LAYOUT.FOOTER.COPYRIGHT')}{' '}
                            </p>
                            <div className={style.condition_txt}>
                                <div>
                                    <a href="/company/terms">{translate('LIABILITY_LAYOUT.FOOTER.TERMS')}</a>
                                </div>
                                <div>
                                    <a href="/privacy-policy">{translate('LIABILITY_LAYOUT.FOOTER.PRIVACY')}</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Container>

            <div className={style.footer_bottom_section}>
                <div className={style.payment_sec}>
                    <Container>
                        <div className={style.footerBottom}>
                            <div className={style.footerImage}>
                                <div className={style.footerimageBox}>
                                    <img src={visa} alt="visa" />
                                    {/* <img src={amex} alt="amex" /> */}
                                    <img src={mastercard} alt="Mastercard" />
                                    {/* <img src={googlePay} alt="googlePay" /> */}
                                </div>
                                <h5>{translate('LIABILITY_LAYOUT.FOOTER.PAYMENT_METHODS_TITLE')}</h5>
                            </div>
                            <div className={style.securedSectopn}>
                                <h5>{translate('LIABILITY_LAYOUT.FOOTER.PAYMENT_METHODS_SECURED_WITH')}</h5>
                                <img src={frame99} alt="pci dss" />
                            </div>
                        </div>
                    </Container>
                </div>
                <Container>
                    <div className={style.copyrightSection}>
                        <div className={style.copyrightBox}>
                            <p>
                                {translate('LIABILITY_LAYOUT.FOOTER.COPYRIGHT')}{' '}
                            </p>
                            <ul>
                                <li>
                                    <a href="/company/terms">{translate('LIABILITY_LAYOUT.FOOTER.TERMS')}</a>
                                </li>
                                <li>
                                    <a href="/privacy-policy">{translate('LIABILITY_LAYOUT.FOOTER.PRIVACY')}</a>
                                </li>
                                {/* <li>
                                <a href="#">Contact</a>
                            </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className={style.disclaimer_space}>
                        <div className={style.disclaimerSection}>
                            <p>
                                {translate('LIABILITY_LAYOUT.FOOTER.BROKING_LICENSE')}
                            </p>
                        </div>
                        <div className={style.disclaimerSection}>
                            <p>
                                {translate('LIABILITY_LAYOUT.FOOTER.REGISTRATION_NO')}
                            </p>
                        </div>
                        <div className={style.disclaimerSection}>
                            <p>
                                {translate('LIABILITY_LAYOUT.FOOTER.DISCLAIMER')}
                            </p>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default Footer;
