import Business from '../../assets/images/Business.png';
import network from '../../assets/images/network.svg';
import rating from '../../assets/images/rating.svg';
import GetBussiness from '../../assets/images/get-bussiness.png';
// import cllogo1 from '../../assets/images/cl_logo1.png';
// import cllogo2 from '../../assets/images/cl_logo2.png';
// import cllogo3 from '../../assets/images/cl_logo3.png';
// import cllogo5 from '../../assets/images/cl_logo5.png';
// import cllogo6 from '../../assets/images/cl_logo6.png';
import article from '../../assets/images/article.png';
import ArrowBtn from '../../assets/images/ArrowBtn.png';
import businessesInsuranceImage from '../../assets/images/businesses-insurance.png';
import docFileImage from '../../assets/images/doc-file-check.png';
import cyberLinkImage from '../../assets/images/cyber-link.png';
import MainBanner from '../../common/MainBanner';
import { Container, Row } from 'react-bootstrap';
import SubBanner from '../../common/SubBanner';
// import Images from "../../common/Images";
import CustomCards from '../../common/CustomCards';
import RatingCards from './RatingCards';
import ScrollManu from './ScrollManu';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { GetPolicyByIdAction } from '../../Redux/Actions/HomepageActions';
import { useNavigate, useParams } from 'react-router-dom';
import { IReduxState } from '../../utils/types';
import CustomLoader from '../../common/Loader/CustomLoader';

const BusinessOwnerPolicy = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { policyId }: any = useParams();
    const { view_single_policy, loading } = useSelector(
        (state: IReduxState) => state.Homepage,
    );

    useEffect(() => {
        dispatch(GetPolicyByIdAction(policyId));
    }, [policyId]);

    return (
        <>
            {loading ? (
                <div className="m-5">
                    <CustomLoader />
                </div>
            ) : (
                <div>
                    {/* <MainBanner mainTitle="Business Owners" subTitle="Policy" content={`<ul>
                            <li>COVRZY’s Business Owners Policy (BOP) includes market-leading
                            commercial property, general liability, and business interruption
                            insurance. Get it in minutes, not days</li>
                        </ul>`} image={Business} /> */}
                    <MainBanner
                        mainTitle={view_single_policy?.policy_name}
                        // subTitle="Policy"
                        content={`<ul>
                            <li>${view_single_policy?.policy_defination}</li>
                        </ul>`}
                        image={Business}
                        showbutton
                    />
                    <ScrollManu />
                    <SubBanner
                        bgImage={GetBussiness}
                        whiteTitle={`Get your<br/> ${view_single_policy?.policy_name}<br/> quote in less than 10 minutes`}
                        buttonTitle="Get Your Coverage Now"
                        onClick={() => navigate('/user/user-detail')}
                        image={network}
                    />
                    {/* <section className='rating-card'>
                        <Container>
                            <Row>
                                <RatingCards image={rating} content={`“COVRZY has given the insurance industry
                                    a much-needed makeover that transforms
                                    something I always considered monotonous
                                    and dull into something streamlined
                                    and simple.”`} linkTitle="Ben Rollins" title="Axon Optics" />
                                <RatingCards image={rating} content={`“COVRZY has given the insurance industry
                                    a much-needed makeover that transforms
                                    something I always considered monotonous
                                    and dull into something streamlined
                                    and simple.”`} linkTitle="Ben Rollins" title="Axon Optics" />
                                <RatingCards image={rating} content={`“COVRZY has given the insurance industry
                                    a much-needed makeover that transforms
                                    something I always considered monotonous
                                    and dull into something streamlined
                                    and simple.”`} linkTitle="Ben Rollins" title="Axon Optics" />
                            </Row>
                        </Container>
                    </section> */}
                    {/* <section className="trusted-companies">
                        <Container>
                            <div className="trusted-logo">
                                <Images image={cllogo6} />
                                <Images image={cllogo1} />
                                <Images image={cllogo2} />
                                <Images image={cllogo3} />
                                <Images image={cllogo5} />
                            </div>
                        </Container>
                    </section> */}
                    <section className="article-section">
                        <Container>
                            {/* <h2 className="title-wrp">Related <span>Articles</span></h2> */}
                            <h2 className="title-wrp">
                                <span>Articles</span> that can help
                            </h2>
                            <Row>
                                <CustomCards
                                    image={docFileImage}
                                    // content={`How Much Does a ${view_single_policy?.policy_name} Cost?`}
                                    content={
                                        'What is liability insurance and how it works?'
                                    }
                                    buttonImage={ArrowBtn}
                                    link="https://www.cnbctv18.com/personal-finance/what-is-liability-insurance-and-how-it-works-types-benefits-explained-15995941.htm"
                                />
                                <CustomCards
                                    image={businessesInsuranceImage}
                                    content={
                                        'The ignored need of insurance by small businesses.'
                                    }
                                    buttonImage={ArrowBtn}
                                    link="https://www.livemint.com/money/personal-finance/the-ignored-need-of-insurance-by-small-businesses-11670857104709.html"
                                />
                                <CustomCards
                                    image={cyberLinkImage}
                                    content={
                                        'Why cyber-security needs to be a strategy in the infinite corporate game?'
                                    }
                                    buttonImage={ArrowBtn}
                                    link="https://www.forbesindia.com/article/iim-calcutta/why-cybersecurity-needs-to-be-a-strategy-in-the-infinite-corpo-rate-game/80589/1"
                                />
                            </Row>
                        </Container>
                    </section>
                </div>
            )}
        </>
    );
};

export default BusinessOwnerPolicy;
