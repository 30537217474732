import React, { useState, useEffect } from 'react';
import Ankit from '../../assets/images/ankit.jpeg';
import CallIcon from '../../assets/images/call-icon.svg';
import arrowIcon from '../../assets/images/curved-arrow-w.svg';
import EstimateSummaryCard from '../EstimateSummary';
import Style from './style.module.scss';
import CallbackModal from '../RequestCallbackl';
import { IReduxState } from '../../utils/types';
import { useSelector } from 'react-redux';
import additional_img from '../../assets/images/additional_policyimg.svg';
import RequestCallbackFormModal from '../../common/RequestCallbackFormModal';
import useMediaQuery from '../../common/MediaHook';

interface SidebarProps {
    sidebarHeader: string;
    sideBarDesc: string;
    bannerImg: string;
    showEstimate: boolean;
    index?: number;
    setIndex?: React.Dispatch<React.SetStateAction<number>>
}

const INDEX_STEPS = [
    'payment_completed',
    'policy_details',
    'insured_details',
    'upload_details',
    'subsidy_details',
]

function Sidebar({ index = 0, ...props }: SidebarProps) {
    const [open, setOpen] = useState<boolean>(false);
    const { callback_success } = useSelector(
        (state: IReduxState) => state.Support,
    );

    const isDesktop = useMediaQuery('(min-width: 576px)');

    const userSession = useSelector((state: IReduxState) => state.UserSession);

    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    const handleModalOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (callback_success) {
            setOpen(false);
        }
    }, [callback_success]);

    useEffect(() => {
        const findCurrentIndex = INDEX_STEPS.indexOf(userSession.currentState)

        if (findCurrentIndex !== -1) {
            setCurrentStepIndex(findCurrentIndex)
        } else {
            setCurrentStepIndex(0)
        }

    }, [userSession.currentState]);
    return (
        <>
            <div className={Style.main}>
                <div className={Style.mainContainer}>

                    {props.showEstimate ? (
                        isDesktop ? <EstimateSummaryCard index={index} setIndex={props.setIndex} /> : <>Implment drawer</>
                    ) : (
                        (index < 6 || index === 7) ? <div className={Style.container}>
                            <h5>{props.sidebarHeader}</h5>
                            <p>{props.sideBarDesc}</p>
                            <div className={Style.card}>
                                <img src={Ankit} alt="ankit-img" />
                                <div className={Style.content}>
                                    <h4>Hello, I’m Ankit.</h4>
                                    <p>
                                        I’m the Co-Founder & CEO of Covrzy and I’m here to assist you in getting the best insurance coverage for your company.
                                    </p>
                                </div>
                            </div>
                            <div className={Style.button}>
                                <button onClick={handleModalOpen}>
                                    Request a call back{' '}
                                    <img src={CallIcon} alt="" />
                                </button>
                                <img src={arrowIcon} alt="arrow" />
                            </div>
                        </div> :

                            <div className={Style.container}>
                                <h5>Thank You for
                                    Choosing Covrzy</h5>
                                <p>Help us craft your final policy copy by sharing a bit more information.</p>


                                <div className={Style.card_dropdown}>
                                    <div className={Style.card_dropdown_content}>
                                        <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="27" viewBox="0 0 20 27" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8702 8.47214H8.60047V12.1777H14.527V8.47214H13.8702ZM19.6873 24.4504H16.8569V26.6833C16.8569 26.8575 16.7162 27 16.5442 27H0.312744C0.140735 27 0 26.8575 0 26.6833V2.86628C0 2.69208 0.140735 2.54956 0.312744 2.54956H3.12744V0.316716C3.12744 0.142522 3.26818 0 3.44019 0H14.3706L14.6208 0.0791789L19.9062 5.05161C19.9687 5.11496 20 5.20997 20 5.30499V24.1337C20 24.3079 19.8593 24.4504 19.6873 24.4504ZM16.2314 24.4504H3.44019C3.26818 24.4504 3.12744 24.3079 3.12744 24.1337V3.18299H0.625489V26.3666H16.2314V24.4504ZM14.0891 0.633431H3.75293V23.817H19.3745V5.60587H14.4019C14.2299 5.60587 14.0891 5.46334 14.0891 5.28915V0.633431ZM14.7146 4.97243H18.8898L14.7146 1.04516V4.97243ZM16.81 15.0282H6.31744C5.91087 15.0282 5.91087 14.3947 6.31744 14.3947H16.8256C17.2322 14.3947 17.2322 15.0282 16.81 15.0282ZM16.81 18.0686H6.31744C5.91087 18.0686 5.91087 17.4352 6.31744 17.4352H16.8256C17.2322 17.4352 17.2322 18.0686 16.81 18.0686ZM16.81 21.1091H6.31744C5.91087 21.1091 5.91087 20.4757 6.31744 20.4757H16.8256C17.2322 20.4757 17.2322 21.1091 16.81 21.1091ZM8.94449 7.83871V7.31613C8.94449 5.85924 10.1173 4.67155 11.5715 4.67155C13.0102 4.67155 14.183 5.85924 14.183 7.31613V7.83871C14.6208 7.83871 15.1525 7.72786 15.1525 8.15543V12.4944C15.1525 12.6686 15.0117 12.8111 14.8397 12.8111H8.28773C8.11572 12.8111 7.97498 12.6686 7.97498 12.4944V8.15543C7.97498 7.72786 8.52228 7.83871 8.94449 7.83871ZM9.58561 7.83871H13.5575V7.31613C13.5575 6.20762 12.6661 5.30499 11.5715 5.30499C10.4769 5.30499 9.58561 6.20762 9.58561 7.31613V7.83871Z" fill="#774AD9" />
                                        </svg></span>
                                        <h5>Additional Policy Details</h5>
                                    </div>
                                    <div className={Style.content_menu}>
                                        <div className={`${Style.content_menu_item} ${currentStepIndex > 0 ? Style.content_menu_completed : ''} ${currentStepIndex === 0 ? Style.content_menu_active : ''}`}>
                                            <p>Policy Details</p>
                                        </div>
                                        <div className={`${Style.content_menu_item} ${currentStepIndex > 1 ? Style.content_menu_completed : ''} ${currentStepIndex === 1 ? Style.content_menu_active : ''}`}>
                                            <p>Insured Details</p>
                                        </div>
                                        <div className={`${Style.content_menu_item} ${currentStepIndex > 2 ? Style.content_menu_completed : ''} ${currentStepIndex === 2 ? Style.content_menu_active : ''}`}>
                                            <p>Upload Documents</p>
                                        </div>
                                        <div className={`${Style.content_menu_item} ${currentStepIndex > 3 ? Style.content_menu_completed : ''} ${currentStepIndex === 3 ? Style.content_menu_active : ''}`}>
                                            <p>Subsidiary Details</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                    )}

                    {!props.showEstimate && (
                        <div className={Style.banner}>
                            <img src={props?.bannerImg ?? additional_img} alt="" />
                        </div>
                    )}
                </div>
            </div>
            {open && (
                <RequestCallbackFormModal showRequestCallbackFormModal={open} setShowRequestCallbackFormModal={setOpen} />)}
        </>
    );
}

export default Sidebar;
