import { call, put, select } from 'redux-saga/effects';
import axios from '../../axiosConfig';
import {
    CREATE_KYC_ERROR,
    CREATE_KYC_SUCCESS,
    GET_USER_SESSIONS_FAILURE_RESPONSE,
    GET_USER_SESSIONS_SUCCESS_RESPONSE,
    GET_USER_SESSION_BY_ID_FAILURE_RESPONSE,
    GET_USER_SESSION_BY_ID_SUCCESS_RESPONSE,
    SAVE_USER_STATE_FAILURE_RESPONSE,
    SAVE_USER_STATE_SUCCESS_RESPONSE,
    UPDATE_USER_SESSION_ACTION,
    UPDATE_USER_SESSION_FAILURE_RESPONSE,
    UPDATE_USER_SESSION_SUCCESS_RESPONSE,
} from '../Actions/SessionAction';
import { TOAST_SHOW } from '../Actions/ToastAction';

interface ISaveUserSession {
    payload: {
        newUserSessionState: 'quote_initiated';
        userSessionData?: Record<string, any>;
    };
}
interface ISessionId {
    payload: {
        id: string;
    };
}

export interface IUpdateUserSessionType {
    id?: number;
    correlationId?: string;
    paymentId?: string;
    nextSessionState?: string;
    userSessionData?: Record<string, any>;
}
interface IUpdateUser {
    payload: IUpdateUserSessionType;
}

enum UserSessionFilter {
    All = 'all',
    Active = 'active',
    Completed = 'completed',
    PaymentCompleted = 'paymentCompleted',
}
export function* SaveUserSessionSaga(actions: ISaveUserSession): unknown {
    try {
        const response = yield call(
            axios.post,
            `v1/users/sessions`,
            actions.payload,
        );
        const { data, success } = response?.data;

        if (success) {
            yield put({
                type: SAVE_USER_STATE_SUCCESS_RESPONSE,
                payload: data,
            });
        }
    } catch (err) {
        yield put({
            type: SAVE_USER_STATE_FAILURE_RESPONSE,
        });
        console.log(err);
    }
}

export function* UpdateUserSessionSaga(actions: IUpdateUser): unknown {
    try {
        if (actions.payload.id) {
            const response = yield call(
                axios.patch,
                `/v1/users/sessions/${actions.payload.id}`,

                actions.payload,
            );

            const { data, success } = response?.data;

            if (success) {
                yield put({
                    type: UPDATE_USER_SESSION_SUCCESS_RESPONSE,
                    payload: data,
                });
            }
        }
    } catch (error) {
        yield put({ type: UPDATE_USER_SESSION_FAILURE_RESPONSE });
        console.log(error);
    }
}

export function* GetUserSessionByIdSaga(actions: ISessionId): unknown {
    try {
        const id = actions.payload.id;
        const response = yield call(axios.get, `/v1/users/sessions/${id}`);
        const { success, data } = response?.data;

        if (success) {
            yield put({
                type: GET_USER_SESSION_BY_ID_SUCCESS_RESPONSE,
                payload: data,
            });
        }
    } catch (error) {
        yield put({ type: GET_USER_SESSION_BY_ID_FAILURE_RESPONSE });
    }
}

export function* GetUserSessionSaga(actions: {
    type: string;
    payload: UserSessionFilter;
}): unknown {
    try {
        const response = yield call(axios.get, `/v1/users/sessions`, {
            params: {
                filterBy: actions.payload,
            },
        });
        const { success, data } = response.data;

        if (success) {
            const lastSession = data[0];
            const payload = lastSession?.isPaymentCompleted ? {} : lastSession;

            yield put({
                type: GET_USER_SESSIONS_SUCCESS_RESPONSE,
                payload: payload,
            });
        }
    } catch (error) {
        yield put({ type: GET_USER_SESSIONS_FAILURE_RESPONSE });
        console.log(error);
    }
}


export function* createKYCSaga(actions: any): unknown {
    try {
        const response = yield call(
            axios.post,
            `v1/kyc/create`,
            actions.payload.formData,
        );

        const { data, success } = response?.data;

        const { UserSession, Payment } = yield select();
        const userSession = UserSession;
        const { paymentResponse } = Payment;


        if (success) {
            // update the user session data
            try {

                yield put({
                    type: CREATE_KYC_SUCCESS,
                });


                const object: any = {};
                actions.payload.formData.forEach((value: any, key: string) => object[key] = value);
                delete object.gst;
                delete object.pan;

                const payload = {
                    id: userSession.id, // always add for params
                    nextSessionState: 'upload_details',
                    paymentId: paymentResponse.payment_id,
                    userSessionData: {
                        additionalKycDetails: object,
                    },
                }


                yield put({
                    type: UPDATE_USER_SESSION_ACTION,
                    payload: payload,
                });


                // const initateResponse = yield call(
                //     axios.post,
                //     `v1/purchases/initiate-manual-sme-purchase`,
                //     actions.payload.manualPurchaseData,
                // );

                // const { success: initateSuccess } = initateResponse?.data;
                // if (initateSuccess) {
                //     yield put({
                //         type: CREATE_KYC_SUCCESS,
                //         payload: data,
                //     });

                //     yield put({
                //         type: SET_MERCHANT_STEP_REQUEST,
                //         payload: {
                //             requestMetadata: quotation,
                //             responseMetadata: quotationDetails,
                //             stepNumber: currentStep,
                //         },
                //     });
                // }
            } catch (err: any) {
                yield put({
                    type: TOAST_SHOW,
                    payload: {
                        message:
                            err?.response?.data?.errors?.Error?.message ||
                            'Something went wrong',
                        severity: 'danger',
                        show: true,
                    },
                });
                console.log(err);
            }
        }
    } catch (err: any) {
        yield put({
            type: CREATE_KYC_ERROR,
        });
        yield put({
            type: TOAST_SHOW,
            payload: {
                message:
                    err?.response?.data?.errors?.Error?.message ||
                    'Something went wrong',
                severity: 'danger',
                show: true,
            },
        });
        console.log(err);
    }
}