import React, { useState } from 'react';
import style from './style.module.scss';
import { Row, Container, Col, Accordion } from 'react-bootstrap';
import simple from '../../assets/images/simple-icon.svg';
import simple02 from '../../assets/images/simple-02-icon.svg';
import simple03 from '../../assets/images/simple-03-icon.svg';
import simple04 from '../../assets/images/simple-04-icon.svg';
import frame01 from '../../assets/images/Frame01.png';
import frame05 from '../../assets/images/frame05.png';
import frame03 from '../../assets/images/frame03.png';
import frame04 from '../../assets/images/frame04.png';
import fullStar from '../../assets/images/full-star.png';
import halfStar from '../../assets/images/half-star.png';
import emptyStar from '../../assets/images/empty-star.png';
import testimonial_mopp_foods from '../../assets/images/testimonial_mopp_foods.png';
import testimonial_karbon from '../../assets/images/testimonial_karbon.png';
import testimonial_venorpay from '../../assets/images/testimonial_volopay.png';
import irdai from '../../assets/images/irdai-logo.png';
import icici from '../../assets/images/icici.png';
import digit from '../../assets/images/digit.png';
import reliance from '../../assets/images/Reliance.png';
import ankit from '../../assets/images/ankit02.png';
import whyUsImage from '../../assets/images/why_us.png';
import whyUsImageMobile from '../../assets/images/whyus_small.svg';
import purplestar from '../../assets/images/purple-star.png';
import hdfc from '../../assets/images/hdfc.png';
import iffco from '../../assets/images/iffco.png';
import Slider from 'react-slick';
// import RequestCallbackFormModal from '../RequestCallbackFormModal';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useMediaQuery from '../../common/MediaHook';
import { Link } from 'react-router-dom';
// import { openPopupRequestCallback } from '../../Redux/Actions/PopupActions';

function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return <div className={className} onClick={onClick} />;
}

function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return <div className={className} onClick={onClick} />;
}

interface ILiabilityCovrzyDetailsProps {
    showWhyUsSection?: boolean;
    showFeaturedInSection?: boolean;
    showJoinBusinessSection?: boolean;
    showTestimonialSection?: boolean;
    showCollaborationSection?: boolean;
}
const LiabilityCovrzyDetails: React.FunctionComponent<
    ILiabilityCovrzyDetailsProps
> = ({
    showWhyUsSection = false,
    showFeaturedInSection = false,
    showJoinBusinessSection = false,
    showTestimonialSection = false,
    showCollaborationSection = false,
}) => {
    const { t: translate } = useTranslation();

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        initialSlide: 4,
        slidesToScroll: 3,
        arrow: true,
        nav: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        gap: 20,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: false,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const testimonials = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        initialSlide: 3,
        slidesToScroll: 3,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        gap: 15,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: false,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };

    const customerReviews = [
        {
            stars: 5,
            description:
                'The claim process was seamless and I am happy to have associated with Covrzy for our insurance requirements.',
            image: testimonial_mopp_foods,
            company: 'MOPP Foods',
        },
        {
            stars: 4.5,
            description:
                'Covrzy has an amazing team, thank you for all the help throughout the process.',
            image: testimonial_karbon,
            company: 'Karbon',
        },
        {
            stars: 4,
            description:
                'It was taking a while to structure our insurance policy, Covrzy was able to fast-track the process for us.',
            image: testimonial_venorpay,
            company: 'Volopay',
        },
    ];

    /**
     * Dispatches an action to open the request callback pop-up.
     */
    const dispatch = useDispatch();

    /**
     * Handles opening the request callback pop-up.
     * Dispatches the openPopupRequestCallback action.
     */
    const handleRequestCallbackOpen = () => {
        // Dispatch the openPopupRequestCallback action to open the request callback pop-up
        // dispatch(openPopupRequestCallback());
    };

    const isMax576 = useMediaQuery('(max-width: 576px)');

    const StarRating = ({ rating }: { rating: number }) => {
        // Calculate the number of full stars
        const fullStars = Math.floor(rating);

        // Calculate the number of half stars
        const decimalPart = rating - fullStars;
        let halfStars = 0;

        if (decimalPart >= 0.25 && decimalPart < 0.75) {
            halfStars = 0.5;
        } else if (decimalPart >= 0.75) {
            halfStars = 1;
        }

        // Calculate the number of empty stars
        const emptyStars = Math.floor(5 - fullStars - halfStars);

        // Generate the stars
        const stars = [];
        for (let i = 0; i < fullStars; i++) {
            stars.push(<img src={fullStar} alt="full-star" />);
        }
        if (halfStars === 0.5) {
            stars.push(<img src={halfStar} alt="half-star" />);
        }
        for (let i = 0; i < emptyStars; i++) {
            stars.push(<img src={emptyStar} alt="empty-star" />);
        }

        return (
            <>
                {stars.map((star: any, index: number) => (
                    <div key={index}>{star}</div>
                ))}
            </>
        );
    };

    const NEWS_DATA = [
        {
            image: frame01,
            link: 'https://startup.outlookindia.com/sector/fintech/insurtech-start-up-covrzy-raises-rs-3-2-crore-in-pre-seed-funding-led-by-antler-news-8497',
            description:
                'Insurtech start-up Covrzy raises rs 3.2 crore in pre-seed funding led by antler.',
        },
        {
            image: frame05,
            link: 'https://ibsintelligence.com/ibsi-news/5-fintechs-simplifying-business-insurance-for-indian-startups-and-smes/',
            description:
                'Fintechs simplifying business insurance for Indian startups and SMEs',
        },
        {
            image: frame03,
            link: 'https://startupstorymedia.com/stories-2024-05-covrzy-startup-story/',
            description:
                'Pioneering Business Insurance Innovation: The Journey of Covrzy',
        },
        {
            image: frame04,
            link: 'https://inc42.com/startups/30-startups-to-watch-startups-that-caught-our-eyes-in-august-2023/',
            description: 'Startups that caught our eyes',
        },
        {
            image: frame01,
            link: 'https://startup.outlookindia.com/sector/fintech/insurtech-start-up-covrzy-raises-rs-3-2-crore-in-pre-seed-funding-led-by-antler-news-8497',
            description:
                'Insurtech start-up Covrzy raises rs 3.2 crore in pre-seed funding led by antler.',
        },
        {
            image: frame05,
            link: 'https://ibsintelligence.com/ibsi-news/5-fintechs-simplifying-business-insurance-for-indian-startups-and-smes/',
            description:
                'Fintechs simplifying business insurance for Indian startups and SMEs',
        },
        {
            image: frame03,
            link: 'https://startupstorymedia.com/stories-2024-05-covrzy-startup-story/',
            description:
                'Pioneering Business Insurance Innovation: The Journey of Covrzy',
        },
        {
            image: frame04,
            link: 'https://inc42.com/startups/30-startups-to-watch-startups-that-caught-our-eyes-in-august-2023/',
            description: 'Startups that caught our eyes',
        },
    ];

    const WHY_US_POINTS = [
        {
            image: simple,
            title: translate(
                'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.WHY_US_SECTION.POINTS.1',
            ),
        },
        {
            image: simple02,
            title: translate(
                'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.WHY_US_SECTION.POINTS.2',
            ),
        },
        {
            image: simple03,
            title: translate(
                'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.WHY_US_SECTION.POINTS.3',
            ),
        },
        {
            image: simple04,
            title: translate(
                'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.WHY_US_SECTION.POINTS.4',
            ),
        },
    ];

    return (
        <div className={style.CovrzyDetails} id="about-us-section">
            <div className={style.CovrzyDetailsSection}>
                <Container className={style.herocontainer}>
                    <div className={style.mobile_detailsHeading}>
                        <h2>
                            {translate(
                                'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.WHY_US_SECTION.TITLE',
                            )}
                        </h2>
                    </div>
                    <Row
                        // style={{ display: showWhyUsSection ? 'flex' : 'none' ,flexDirection: 'column-reverse'}}
                        className={style.custom_row}
                    >
                        <Col xs={12} md={6} lg={5}>
                            <div className={style.detailsBox}>
                                <div className={style.detailsHeading}>
                                    <h2>
                                        {translate(
                                            'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.WHY_US_SECTION.TITLE',
                                        )}
                                    </h2>
                                    <p>
                                        {translate(
                                            'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.WHY_US_SECTION.DESCRIPTION',
                                        )}
                                    </p>
                                </div>
                                <div>
                                    <ul className={style.iconLsit}>
                                        {WHY_US_POINTS.map((item, index) => (
                                            <li key={index}>
                                                <a href="#">
                                                    <img
                                                        src={item.image}
                                                        alt={item.title}
                                                    />{' '}
                                                    <span>{item.title}</span>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={7}>
                            <div className={style.detailsImage}>
                                <div className={style.videoImage}>
                                    {/* <img src={firefly} alt="firefly" /> */}
                                    <img
                                        src={
                                            isMax576
                                                ? whyUsImageMobile
                                                : whyUsImage
                                        }
                                        alt="whyUsImage"
                                    />
                                    <p>
                                        {translate(
                                            'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.WHY_US_SECTION.DESCRIPTION',
                                        )}
                                    </p>
                                </div>
                                {/* <p>How Covrzy helps you insure your business</p> */}
                                <div className={style.playBtn}>
                                    {/* <img src={play} alt="play" /> */}
                                </div>
                            </div>
                            {/* <div className={style.powerdText}>
                                <span>Powered by Covrzy</span>
                            </div> */}
                        </Col>
                    </Row>
                    <div
                        className={style.featuredSection}
                        id="news-section"
                        style={{
                            display: showFeaturedInSection ? 'block' : 'none',
                        }}
                    >
                        <div className={style.featureHeading}>
                            <h2>
                                {translate(
                                    'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.FEATURED_IN.TITLE',
                                )}

                                <span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="7"
                                        height="14"
                                        viewBox="0 0 7 14"
                                        fill="none"
                                    >
                                        <path
                                            d="M1 12.5L5.25 7L1 1.5"
                                            stroke="black"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </span>
                            </h2>
                        </div>
                        <div className={style.outlookSlider}>
                            <Slider {...settings}>
                                {NEWS_DATA.map((item, index) => (
                                    <div key={index}>
                                        <div className={style.outlookSection}>
                                            <a href={item.link} target="_blank">
                                                <div
                                                    className={style.imgtextBox}
                                                >
                                                    <img
                                                        src={item.image}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className={style.textBox}>
                                                    {item.description}
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className={style.outlooksectionBox}
                                        >
                                            <a href={item.link} target="_blank">
                                                <div
                                                    className={style.imgtextBox}
                                                >
                                                    <img
                                                        src={item.image}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className={style.textBox}>
                                                    {item.description}
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </Container>
            </div>
            <div
                className={style.businessesWrp}
                style={{
                    display:
                        showJoinBusinessSection ||
                        showTestimonialSection ||
                        showCollaborationSection
                            ? 'block'
                            : 'none',
                }}
            >
                <div
                    className={style.businessesHeadning}
                    style={{
                        display: showJoinBusinessSection ? 'block' : 'none',
                    }}
                >
                    <h2>
                        {translate(
                            'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.JOIN_BUSINESS_SECTION.TITLE',
                        )}
                    </h2>
                </div>
                <div className={style.boxWrap}>
                    <Container>
                        <Row>
                            <Col
                                xs={6}
                                md={6}
                                lg={3}
                                className={style.topBorder}
                            >
                                <div className={style.textWrp}>
                                    <h2>3500+</h2>
                                    <p>
                                        {translate(
                                            'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.JOIN_BUSINESS_SECTION.BUSINESSES_INSURED',
                                        )}
                                    </p>
                                </div>
                            </Col>
                            <Col
                                xs={6}
                                md={6}
                                lg={3}
                                className={style.rightBorder}
                            >
                                <div className={style.textWrp}>
                                    <h2>4000+</h2>
                                    <p>{translate('Policies Issues')}</p>
                                </div>
                            </Col>

                            <Col
                                xs={6}
                                md={6}
                                lg={3}
                                className={style.leftBorder}
                            >
                                <div className={style.textWrp}>
                                    <h2>94.5%</h2>
                                    <p>
                                        {translate('Claim to Settlement Ratio')}
                                    </p>
                                </div>
                            </Col>

                            <Col
                                xs={6}
                                md={6}
                                lg={3}
                                className={style.bottomBorder}
                            >
                                <div
                                    className={`${style.textWrp} ${style.lastChild}`}
                                >
                                    <h2>
                                        {/* {translate(
                                            'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.JOIN_BUSINESS_SECTION.7_DAYS',
                                        )} */}
                                        2 Days
                                    </h2>
                                    <p>{translate('Fastest Claim Settled')}</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className={style.customersSection}
                    style={{
                        display: showTestimonialSection ? 'block' : 'none',
                    }}
                >
                    <div className={style.customerHeading}>
                        <h2>
                            {translate(
                                'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.TESTIMONIAL_SECTION.TITLE',
                            )}

                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="7"
                                    height="14"
                                    viewBox="0 0 7 14"
                                    fill="none"
                                >
                                    <path
                                        d="M1 12.5L5.25 7L1 1.5"
                                        stroke="black"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </span>
                        </h2>
                    </div>
                    <Container className={style.insurance_container}>
                        <div
                            className={`slider-container ${style.InsuranceSection}`}
                        >
                            <Slider {...testimonials}>
                                {customerReviews?.map((item, index) => (
                                    <div
                                        className={style.testimonialsSlider}
                                        key={index}
                                    >
                                        <div className={style.testimonialsBox}>
                                            <div
                                                className={
                                                    style.testimonialsImage
                                                }
                                            >
                                                <StarRating
                                                    rating={item.stars}
                                                />
                                            </div>
                                            <div className="testimonialsText">
                                                <p>{item.description}</p>
                                            </div>
                                            <div
                                                className={
                                                    style.testimonialsBottom
                                                }
                                            >
                                                <div>
                                                    <img
                                                        src={item.image}
                                                        alt="profile"
                                                        className={
                                                            style.testimonialProfileImage
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        style.testimonialstextBox
                                                    }
                                                >
                                                    <h3>{item.company}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </Container>
                </div>
                {/* recognisedSection */}
                <div
                    className={style.sponsorSection}
                    style={{
                        display: showCollaborationSection ? 'block' : 'none',
                    }}
                >
                    <Container>
                        <Row>
                            {/* <Col lg={3}>
                                <div className={style.recognisedSection}>
                                    <div className={style.recognisedHeading}>
                                        <h2>Recognised by</h2>
                                    </div>
                                </div>
                            </Col> */}
                            <Col lg={12}>
                                <div className={style.partnersSection}>
                                    <div className={style.partnerImage}>
                                        <div
                                            className={style.recognisedSection}
                                        >
                                            <div
                                                className={
                                                    style.recognisedHeading
                                                }
                                            >
                                                <h2>
                                                    {translate(
                                                        'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.COLLAB_SECTION.COLLAB_WITH',
                                                    )}
                                                </h2>
                                            </div>
                                            <img src={irdai} alt="irdai" />
                                        </div>
                                        <div>
                                            <div
                                                className={
                                                    style.partnersHeading
                                                }
                                            >
                                                <h2>
                                                    {translate(
                                                        'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.COLLAB_SECTION.PARTNERS',
                                                    )}
                                                </h2>
                                            </div>
                                            <div
                                                className={style.partnersImage}
                                            >
                                                <img src={icici} alt="icici" />
                                            </div>
                                        </div>
                                        <div className={style.finaceBank}>
                                            <img src={digit} alt="digit" />
                                        </div>
                                        <div className={style.finaceBank}>
                                            <img
                                                src={reliance}
                                                alt="reliance"
                                            />
                                        </div>
                                        <div className={style.finaceBank}>
                                            <img src={hdfc} alt="hdfc" />
                                        </div>
                                        <div className={style.finaceBank}>
                                            <img src={iffco} alt="iffco" />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* guidanceSection */}
                <div className={style.guidanceSection}>
                    <div id="faqs-section">
                        <div className={style.guidanceBox}>
                            <h2>
                                {/* {translate(
                                    'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.GUIDANCE_SECTION.TITLE',
                                )} */}
                                Still unsure which insurance is right for your
                                business?
                            </h2>
                            <h5>
                                {/* {translate(
                                    'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.GUIDANCE_SECTION.FREE_CONSULT',
                                )} */}
                                Get expert advice and guidance
                            </h5>
                            <p>
                                {/* {translate(
                                    'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.GUIDANCE_SECTION.DESCRIPTION',
                                )} */}
                                We will understand the special needs of your
                                business and help you purchase the best-suited
                                insurance
                            </p>
                            <button onClick={handleRequestCallbackOpen}>
                                {/* {translate(
                                    'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.GUIDANCE_SECTION.CALLBACK',
                                )} */}
                                Get Custom Quote
                            </button>
                            <img
                                src={ankit}
                                alt="ankit"
                                className={style.ankitImage}
                            />
                        </div>
                    </div>
                </div>
                {/* accordion */}

                <div className={style.accordionSection}>
                    <Container className={style.herocontainer}>
                        <div className={style.accordionBox}>
                            <div className={style.accordionHeading}>
                                <h2>
                                    {translate(
                                        'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.FAQS_SECTION.TITLE',
                                    )}
                                </h2>
                                <Link to="#">View All</Link>
                            </div>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        {translate(
                                            'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.FAQS_SECTION.QNA_SECTION.QUE1',
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {translate(
                                            'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.FAQS_SECTION.QNA_SECTION.ANS1',
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        {translate(
                                            'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.FAQS_SECTION.QNA_SECTION.QUE2',
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {translate(
                                            'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.FAQS_SECTION.QNA_SECTION.ANS2',
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                        {translate(
                                            'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.FAQS_SECTION.QNA_SECTION.QUE3',
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <>
                                            {translate(
                                                'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.FAQS_SECTION.QNA_SECTION.ANS3',
                                            )}
                                            <ul
                                                className={
                                                    style.faqUnorderedList
                                                }
                                            >
                                                <li>
                                                    {translate(
                                                        'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.FAQS_SECTION.QNA_SECTION.ANS3_POINT1',
                                                    )}
                                                </li>
                                                <li>
                                                    {translate(
                                                        'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.FAQS_SECTION.QNA_SECTION.ANS3_POINT2',
                                                    )}
                                                </li>
                                                <li>
                                                    {translate(
                                                        'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.FAQS_SECTION.QNA_SECTION.ANS3_POINT3',
                                                    )}
                                                </li>
                                            </ul>
                                        </>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>
                                        {translate(
                                            'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.FAQS_SECTION.QNA_SECTION.QUE4',
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {translate(
                                            'COMMON_COMPONENTS.LIABILITY_COVRZY_DETAILS.FAQS_SECTION.QNA_SECTION.ANS4',
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Container>
                </div>
            </div>
            {/* <RequestCallbackFormModal /> */}
        </div>
    );
};

export default LiabilityCovrzyDetails;
